// Types
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';

// Models
import { Tag } from 'app/modules/teamsOld/models';
import { CommentModel } from 'app/modules/comments/models';
import { FullTxnResponse } from 'app/modules/transactions/types/responses';
import { CustomData } from 'app/shared/models';
import { PaginationPayload } from 'app/shared/pagination/models';

export enum EventActionTypes {
  SET_EVENT_FILTERS = '@@events/SET_EVENT_FILTERS',

  EDIT_EVENT = '@@events/EDIT_EVENT',
  EDIT_EVENT_SUCCESS = '@@events/EDIT_EVENT_SUCCESS',

  RETRIEVE_ACTION_EVENTS = '@@events/RETRIEVE_ACTION_EVENTS',
  RETRIEVE_ACTION_EVENTS_SUCCESS = '@@events/RETRIEVE_ACTION_EVENTS_SUCCESS',
}

export interface CustomEventFilters {
  [key: string]: string[] | string | number;
}

export interface RetrieveActionEventsPayload extends PaginationPayload {
  device_ids?: (number | string)[];
  instrument_ids?: (number | string)[];
  entity_ids?: (number | string)[];
  action_types?: string[];
  start_date?: string;
  end_date?: string;
}

export interface ActionEventResponse {
  count: number;
  action_events: ActionEventSummary[];
}

export interface EventEditRequest {
  eventId: string;
  tags?: (string | number)[];
  comment?: string;
}

export interface Address {
  building_number?: string;
  city?: string;
  country?: string;
  postal_code?: string;
  state?: string;
  street_name?: string;
  type?: string;
  unit_number?: string;
}

export type Transaction = FullTxnResponse;

export interface ActionEventSummary extends ShortActionEventResponse {}

export interface ActionEventDetails extends ActionEventSummary {}

export interface Event {
  custom_data: CustomData;
  registered_on: string;
  name: string;
  unit_id: number;
  org_id: number;
  id: number | string;
  location?: string;
  source?: 'web' | 'api' | '';
  created_at?: string;
  type?: string;
  tags: Tag[];
  comments: CommentModel[];
}

export interface EventFiltersState {
  internalTxnTypes: string[];
  internalTxnStatuses: string[];
  txnCodes?: string[]; // delete me
  dateRange: string;
  sender?: boolean;
  receiver?: boolean;
  startDate: string;
  endDate: string;
  tags: number[];
  minimumAmount?: string;
  maximumAmount?: string;
  currency?: string;
  hideSpooled?: boolean;
  entity_ids?: number[];
  customFilters: CustomEventFilters;
  offset?: number;
  limit?: number;
}

export interface EventState extends EventFiltersState {
  actionEvents: ActionEventResponse;
}

export enum EvaluationLogStatus {
  PASS = 'PASS',
  FAIL = 'FAIL',
  ERROR = 'ERROR',
}

export interface EvaluationLog {
  evaluation_id: string;
  event_id: string; // external id of client
  rule_executions: {
    [rule_id: string]: {
      rule_name: string;
      status: EvaluationLogStatus;
    };
  };
}
