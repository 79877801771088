import { WatchlistFeedTypes } from 'app/modules/detectionModels/components/scenarioWidgets/watchlist/enums';
import { WatchlistParameters } from 'app/modules/detectionModels/components/scenarioWidgets/watchlist/models';

export const validateWatchlistContentStep = (
  watchlist: WatchlistParameters | undefined,
): boolean => {
  if (!watchlist) return false;
  if (!watchlist.name) return false;
  if (!watchlist.entity_type) return false;
  if (!isMatchThresholdValid(watchlist.match_threshold)) {
    return false;
  }
  if (watchlist.match_fields.length === 0) return false;
  if (!watchlist.is_specification_valid) return false;
  if (!isFeedFilterValid(watchlist)) return false;
  return true;
};

export const isMatchThresholdValid = (
  matchThreshold: number | undefined,
): boolean => {
  if (matchThreshold === undefined) return false;
  return matchThreshold >= -5 && matchThreshold <= 10;
};

export const isFeedFilterValid = (watchlist: WatchlistParameters): boolean => {
  if (watchlist.must_match_feed_filters) {
    if (watchlist.name === WatchlistFeedTypes.PEP) {
      if (watchlist.feed_filters.pep_level.length <= 0) {
        return false;
      }
    } else if (watchlist.name === WatchlistFeedTypes.SANCTIONS) {
      if (watchlist.feed_filters.sanctions.length <= 0) {
        return false;
      }
    } else if (watchlist.name === WatchlistFeedTypes.ADVERSE_MEDIA) {
      if (watchlist.feed_filters.adverse_media.length <= 0) {
        return false;
      }
    }
  }

  return true;
};
