// Redux
import { Reducer } from 'redux';
import produce, { Draft } from 'immer';

// Models
import { AgentsState, AgentsActionTypes } from 'app/modules/agentsOld/models';

// Actions
import { AgentActions } from 'app/modules/agentsOld/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: Readonly<AgentsState> = {
  // fields the agent is allowed to uncensor
  uncensorFields: [],
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<AgentsState> = (
  state = initialState,
  action: AgentActions,
) => {
  return produce(state, (draft: Draft<AgentsState>) => {
    switch (action.type) {
      case AgentsActionTypes.RETRIEVE_UNCENSOR_FIELDS_SUCCESS:
        draft.uncensorFields = action.payload;
        return;

      default:
        return;
    }
  });
};

export { reducer as agentsReducer, initialState };
