import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CensorshipActionTypes } from 'app/modules/censorship/models';

// Actions
import {
  retrieveCensorshipConditions as retrieveCensorshipConditionsAction,
  retrieveCensorshipConditionsSuccess,
} from 'app/modules/censorship/actions';

// API
import { getCensorshipConditions } from 'app/shared/api/censorship';

const rootAction = CensorshipActionTypes.RETRIEVE_CENSORSHIP_CONDITIONS;
function* retrieveCensorshipConditions({
  payload,
}: ReturnType<typeof retrieveCensorshipConditionsAction>) {
  const config = {
    rootAction,
    request: call(getCensorshipConditions, payload),
    success: function* onSuccess(result) {
      yield put(retrieveCensorshipConditionsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveCensorshipConditions() {
  yield takeLatest(rootAction, retrieveCensorshipConditions);
}
