import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

// Models
import { AlertSummary } from 'app/modules/alerts/models';
import { EntitySummary } from 'app/modules/entities/models';
import { InvestigationType } from 'app/modules/investigations/models';
import { SarSummary } from 'app/modules/fincenSar/models';
import { ShortTxnResponse } from 'app/modules/transactions/types';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';

// Selectors
import {
  selectAlertTableConfig,
  selectEntityTableConfig,
  selectTxnTableConfig,
  selectFilingsTableConfig,
  selectActionEventTableConfig,
} from 'app/shared/CustomConfig/selectors';
import { selectInvestigation } from 'app/modules/investigations/selectors';
import {
  selectHasReadAlertsPermission,
  selectHasReadEntitiesPermissions,
  selectHasReadSARsPermissions,
  selectHasReadEventsPermission,
  selectAgent,
  selectHasReadAssignmentsPermissions,
} from 'app/modules/session/selectors';
import { selectClassifiedQueuesEnabled } from 'app/shared/featureFlags/selectors';

// Components
import { IconLayoutColumns } from '@u21/tabler-icons';
import {
  U21Button,
  U21Section,
  U21Table,
  U21TitleCountLabel,
} from 'app/shared/u21-ui/components';

// Utils
import { createTableColumnConfig } from 'app/shared/utils/table';
import { openInNewTab, ROUTES_MAP } from 'app/shared/utils/routes';
import { isCmdCtrlClick } from 'app/shared/utils/keyboard';
import { isQueueAccessible } from 'app/modules/queues/utils';

// Constants
import { ALERT_COLUMN_CONFIG } from 'app/modules/alerts/columns';
import { ENTITY_COLUMN_CONFIG } from 'app/modules/entitiesRefresh/columns';
import { SAR_COLUMN_CONFIG } from 'app/modules/fincenSar/columns';
import { REPORT_TYPE_PATH_MAP } from 'app/modules/fincenSar/constants/shared';
import { TRANSACTION_COLUMN_CONFIG } from 'app/modules/transactions/columns';
import { ACTION_EVENTS_COLUMN_CONFIG } from 'app/modules/actionEvents/columns';

// Actions
import {
  toggleSidebar,
  toggleTableConfigSidebar,
} from 'app/modules/sidebar/slice';

export enum FormTabTableKeys {
  ALERTS = 'alerts',
  ENTITIES = 'entities',
  TRANSACTION_EVENTS = 'events',
  SARS = 'sars',
  ACTION_EVENTS = 'action_events',
  TRANSACTION_ANALYSIS = 'related_transactions',
}

interface OwnProps {
  keyType: FormTabTableKeys;
}

export const FormTabTable = (props: OwnProps) => {
  const { keyType } = props;
  const dispatch = useDispatch();
  const details = useSelector(selectInvestigation(InvestigationType.CASE));
  const alertTableConfig = useSelector(selectAlertTableConfig);
  const entityTableConfig = useSelector(selectEntityTableConfig);
  const transactionEventTableConfig = useSelector(selectTxnTableConfig);
  const actionEventTableConfig = useSelector(selectActionEventTableConfig);
  const sarFilingTableConfig = useSelector(selectFilingsTableConfig);
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );
  const hasReadEventsPermissions = useSelector(selectHasReadEventsPermission);
  const hasReadSARPermissions = useSelector(selectHasReadSARsPermissions);
  const hasReadAssignmentsPermission = useSelector(
    selectHasReadAssignmentsPermissions,
  );
  const classifiedQueuesEnabled = useSelector(selectClassifiedQueuesEnabled);
  const sessionAgent = useSelector(selectAgent);

  const { label, routeString, title, tableProps, tableType } = useMemo(() => {
    switch (keyType) {
      case FormTabTableKeys.ALERTS:
        return {
          label: 'alert',
          title: 'Alerts',
          routeString: ROUTES_MAP.alertsId.path,
          tableProps: {
            columns: [
              ...createTableColumnConfig<AlertSummary>(
                alertTableConfig,
                ALERT_COLUMN_CONFIG,
              ),
            ],
            isRowClickable: (row: AlertSummary) =>
              !classifiedQueuesEnabled ||
              isQueueAccessible(
                hasReadAssignmentsPermission,
                sessionAgent.accessible_queues,
                row.queue_id,
                row.queue_access_type,
              ),
            onRowClick: hasReadAlertsPermission
              ? (id: number, row: AlertSummary, e: KeyboardEvent) => {
                  if (isCmdCtrlClick(e)) {
                    openInNewTab(routeString.replace(':id', String(id)));
                  } else {
                    dispatch(
                      toggleSidebar({
                        data: { alert: row },
                        type: SidebarComponentTypes.ALERT,
                      }),
                    );
                  }
                }
              : undefined,
          },
          tableType: TableConfigType.ALERT_TABLE,
        };
      case FormTabTableKeys.ENTITIES:
        return {
          label: 'entity',
          title: 'Entities',
          routeString: ROUTES_MAP.entitiesId.path,
          tableProps: {
            columns: [
              ...createTableColumnConfig<EntitySummary>(
                entityTableConfig,
                ENTITY_COLUMN_CONFIG,
              ),
            ],
            onRowClick: hasReadEntitiesPermission
              ? (id: number, _, e: KeyboardEvent) => {
                  if (isCmdCtrlClick(e)) {
                    openInNewTab(routeString.replace(':id', String(id)));
                  } else {
                    dispatch(
                      toggleSidebar({
                        data: { id },
                        type: SidebarComponentTypes.ENTITY,
                      }),
                    );
                  }
                }
              : undefined,
          },
          tableType: TableConfigType.ENTITY_TABLE,
        };
      /* Keeping txn event and txn analysis together until they get
       * too different from each other
       */
      case FormTabTableKeys.TRANSACTION_ANALYSIS:
        return {
          label: 'transactions',
          title: 'Transaction Analysis',
          routeString: ROUTES_MAP.dataExplorerTransactionsId.path,
          tableProps: {
            columns: [
              ...createTableColumnConfig<ShortTxnResponse>(
                transactionEventTableConfig,
                TRANSACTION_COLUMN_CONFIG,
              ),
            ],
            onRowClick: hasReadEventsPermissions
              ? (id: number, _: ShortTxnResponse, e: KeyboardEvent) => {
                  if (isCmdCtrlClick(e)) {
                    openInNewTab(routeString.replace(':id', String(id)));
                  } else {
                    dispatch(
                      toggleSidebar({
                        data: { id },
                        type: SidebarComponentTypes.TRANSACTION,
                      }),
                    );
                  }
                }
              : undefined,
            selectable: true,
          },
          tableType: TableConfigType.TXN_TABLE,
        };
      case FormTabTableKeys.TRANSACTION_EVENTS:
        return {
          label: 'transaction',
          title: 'Transactions',
          routeString: ROUTES_MAP.dataExplorerTransactionsId.path,
          tableProps: {
            columns: [
              ...createTableColumnConfig<ShortTxnResponse>(
                transactionEventTableConfig,
                TRANSACTION_COLUMN_CONFIG,
              ),
            ],
            onRowClick: hasReadEventsPermissions
              ? (id: number, _: ShortTxnResponse, e: KeyboardEvent) => {
                  if (isCmdCtrlClick(e)) {
                    openInNewTab(routeString.replace(':id', String(id)));
                  } else {
                    dispatch(
                      toggleSidebar({
                        data: { id },
                        type: SidebarComponentTypes.TRANSACTION,
                      }),
                    );
                  }
                }
              : undefined,
          },
          tableType: TableConfigType.TXN_TABLE,
        };
      case FormTabTableKeys.ACTION_EVENTS:
        return {
          label: 'action',
          title: 'Action Events',
          routeString: ROUTES_MAP.dataExplorerActionEvents.path,
          tableProps: {
            columns: [
              ...createTableColumnConfig<ShortActionEventResponse>(
                actionEventTableConfig,
                ACTION_EVENTS_COLUMN_CONFIG,
              ),
            ],
            onRowClick: hasReadEventsPermissions
              ? (id: number, _, e: KeyboardEvent) => {
                  if (isCmdCtrlClick(e)) {
                    openInNewTab(routeString.replace(':id', String(id)));
                  } else {
                    dispatch(
                      toggleSidebar({
                        data: { id },
                        type: SidebarComponentTypes.ACTION_EVENT,
                      }),
                    );
                  }
                }
              : undefined,
          },
          tableType: TableConfigType.ACTION_EVENT_TABLE,
        };
      // lump default with the last option because it's impossible
      // for it to be anything else based on typing
      case FormTabTableKeys.SARS:
      default:
        return {
          label: 'filings',
          title: 'Filings',
          routeString: ROUTES_MAP.filingsSarId.path,
          tableProps: {
            columns: [
              ...createTableColumnConfig<SarSummary>(
                sarFilingTableConfig,
                SAR_COLUMN_CONFIG,
              ),
            ],
            isRowClickable: (row: SarSummary) =>
              !classifiedQueuesEnabled ||
              row.queue === null ||
              isQueueAccessible(
                hasReadAssignmentsPermission,
                sessionAgent.accessible_queues,
                row.queue.id,
                row.queue?.access_type,
              ),
            onRowClick: hasReadSARPermissions
              ? (id: number, sar: SarSummary, e: KeyboardEvent) => {
                  if (isCmdCtrlClick(e)) {
                    const { report_type: reportType } = sar;
                    openInNewTab(
                      REPORT_TYPE_PATH_MAP[reportType].replace(
                        ':id',
                        String(id),
                      ),
                    );
                  } else {
                    dispatch(
                      toggleSidebar({
                        data: sar,
                        type: SidebarComponentTypes.SAR,
                      }),
                    );
                  }
                }
              : undefined,
          },
          tableType: TableConfigType.SAR_TABLE,
        };
    }
  }, [
    keyType,
    alertTableConfig,
    hasReadAlertsPermission,
    entityTableConfig,
    hasReadEntitiesPermission,
    transactionEventTableConfig,
    hasReadEventsPermissions,
    actionEventTableConfig,
    sarFilingTableConfig,
    hasReadSARPermissions,
    classifiedQueuesEnabled,
    hasReadAssignmentsPermission,
    sessionAgent.accessible_queues,
    dispatch,
  ]);

  const data = useMemo(() => {
    switch (keyType) {
      case FormTabTableKeys.TRANSACTION_ANALYSIS:
        // do something
        return [];

      default:
        return details?.[keyType] ?? [];
    }
  }, [details, keyType]);

  const handleTableConfig = () => {
    dispatch(toggleTableConfigSidebar({ tableConfigType: tableType }));
  };

  return (
    <U21Section
      action={
        <U21Button
          onClick={handleTableConfig}
          startIcon={<IconLayoutColumns />}
        >
          Choose Columns
        </U21Button>
      }
      title={
        <U21TitleCountLabel count={data?.length ?? 0} label={label}>
          {title}
        </U21TitleCountLabel>
      }
    >
      <U21Table data={data} {...tableProps} />
    </U21Section>
  );
};
