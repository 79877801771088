import { fork, all } from 'redux-saga/effects';
import agentsSagas from 'app/modules/agentsOld/sagas';
import alertsSagas from 'app/modules/alerts/sagas';
import casesSagas from 'app/modules/casesOld/sagas';
import commentsSagas from 'app/modules/comments/sagas';
import notesSagas from 'app/modules/notes/sagas';
import entitiesSagas from 'app/modules/entities/sagas';
import ruleSagas from 'app/modules/rules/sagas';
import sessionSagas from 'app/modules/session/sagas';
import settingsSagas from 'app/modules/settings/sagas';
import uploadSagas from 'app/modules/uploads/sagas';
import teamSagas from 'app/modules/teamsOld/sagas';
import filingSagas from 'app/modules/fincenSar/sagas';
import eventSagas from 'app/shared/events/sagas';
import transactionSagas from 'app/modules/transactions/sagas';
import searchSagas from 'app/modules/search/sagas';
import webhookSagas from 'app/modules/webhooks/sagas';
import workflowSagas from 'app/modules/workflows/sagas';
import insightsSagas from 'app/modules/insights/sagas';
import auditTrailSagas from 'app/shared/auditTrail/sagas';
import dropdownSagas from 'app/shared/dropdown/sagas';
import blacklistSagas from 'app/modules/lists/sagas';
import censorshipSagas from 'app/modules/censorship/sagas';
import deadlinesSagas from 'app/modules/deadlines/sagas';
import customChecklistSagas from 'app/shared/customChecklist/sagas';
import detectionModelsSagas from 'app/modules/detectionModels/sagas';
import permissionsSagas from 'app/modules/permissions/sagas';
import tableConfigSagas from 'app/shared/CustomConfig/sagas';
import goAMLSagas from 'app/modules/goAML/sagas';

const sagaList = [
  ...agentsSagas,
  ...alertsSagas,
  ...auditTrailSagas,
  ...blacklistSagas,
  ...casesSagas,
  ...censorshipSagas,
  ...commentsSagas,
  ...customChecklistSagas,
  ...deadlinesSagas,
  ...dropdownSagas,
  ...entitiesSagas,
  ...eventSagas,
  ...filingSagas,
  ...goAMLSagas,
  ...insightsSagas,
  ...notesSagas,
  ...ruleSagas,
  ...searchSagas,
  ...settingsSagas,
  ...sessionSagas,
  ...uploadSagas,
  ...teamSagas,
  ...transactionSagas,
  ...webhookSagas,
  ...workflowSagas,
  ...detectionModelsSagas,
  ...permissionsSagas,
  ...tableConfigSagas,
];

export default function* rootSaga() {
  yield all(sagaList.map((saga) => fork(saga)));
}
