import { TableConfig } from 'app/shared/pagination/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { DEFAULT_ALERT_QUEUES_TABLE_CONFIG } from 'app/modules/alerts/constants';
import { DEFAULT_CENSORSHIP_TABLE_CONFIG } from 'app/modules/censorship/constants';
import {
  DEFAULT_SAR_QUEUES_TABLE_CONFIG,
  DEFAULT_SAR_TABLE_CONFIG,
} from 'app/modules/fincenSarNew/constants';
import { DEFAULT_CASE_QUEUES_TABLE_CONFIG } from 'app/modules/cases/constants';

export const TABLE_CONFIG_SAVE_SUCCESS_TOAST_MESSAGE: string =
  'Config saved successfully.';
export const TABLE_CONFIG_SAVE_ERROR_TOAST_MESSAGE: string =
  'Something went wrong, unable to save config.';

export const CHILD_ORGS_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'name',
    label: 'Org Name',
    type: 'text',
    sortable: false,
  },
  {
    key: 'created_at',
    label: 'Date Created',
    type: 'datetime',
    sortable: false,
  },
  {
    key: 'status',
    label: 'Status',
    type: 'label',
    sortable: false,
  },
  {
    key: 'active_agents_count',
    label: 'Active Agents',
    type: 'number',
    sortable: false,
  },
];

export const DEFAULT_TABLE_CONFIGS: Partial<
  Record<TableConfigType, TableConfig[]>
> = {
  [TableConfigType.ALERT_QUEUES_TABLE]: DEFAULT_ALERT_QUEUES_TABLE_CONFIG,
  [TableConfigType.CENSORSHIP_TABLE]: DEFAULT_CENSORSHIP_TABLE_CONFIG,
  [TableConfigType.CASE_QUEUES_TABLE]: DEFAULT_CASE_QUEUES_TABLE_CONFIG,
  [TableConfigType.SAR_QUEUES_TABLE]: DEFAULT_SAR_QUEUES_TABLE_CONFIG,
  [TableConfigType.SAR_TABLE]: DEFAULT_SAR_TABLE_CONFIG,
};
