// Models
import { PaginationPayload } from 'app/shared/models';
import { FullAgentResponse } from 'app/modules/agentsOld/types';

export enum AgentsActionTypes {
  START_IMPERSONATION = '@@agents/START_IMPERSONATION',
  START_IMPERSONATION_SUCCESS = '@@agents/START_IMPERSONATION_SUCCESS',
  CANCEL_IMPERSONATION = '@@agents/CANCEL_IMPERSONATION',
  CANCEL_IMPERSONATION_SUCCESS = '@@agents/CANCEL_IMPERSONATION_SUCCESS',

  RETRIEVE_UNCENSOR_FIELDS = '@@agents/RETRIEVE_UNCENSOR_FIELDS',
  RETRIEVE_UNCENSOR_FIELDS_SUCCESS = '@@agents/RETRIEVE_UNCENSOR_FIELDS_SUCCESS',
}

export type AgentStatus =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'AWAITING_LOGIN'
  | 'AWAITING_APPROVAL'
  | '';

export interface Auth0AgentStatsModel {
  last_ip: string;
  last_login: string;
  logins_count: number;
  provider: string;
  external_agent_id: string;
  created_at: string;
  email_verified: boolean;
}

export interface AgentDetails extends FullAgentResponse {}

export interface AgentsState {
  uncensorFields: string[];
}

interface PaginatedAgentsFiltersPayload {
  statuses?: AgentStatus[];
  is_reviewer?: boolean;
  tags?: number[];
  teams?: number[];
  query?: string;
  ids?: number[];
  queue_ids?: number[];
}

export interface PaginatedAgentsPayload
  extends PaginationPayload,
    PaginatedAgentsFiltersPayload {
  org_id?: number;
  article_id?: number;
}

export interface PaginatedAgentsResponse {
  count: number;
  agents: AgentDetails[];
}
