import { call, put, takeLatest } from 'redux-saga/effects';

// Actions
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import {
  uncensorField as uncensorFieldAction,
  uncensorFieldSuccess,
  clearUncensoredField,
} from 'app/modules/censorship/actions';

// API
import { uncensorField as uncensorFieldApi } from 'app/shared/api/censorship';

// Models
import { CensorshipActionTypes } from 'app/modules/censorship/models';

const rootAction = CensorshipActionTypes.UNCENSOR_FIELD;
function* uncensorField({ payload }: ReturnType<typeof uncensorFieldAction>) {
  const config = {
    rootAction,
    request: call(uncensorFieldApi, payload),
    success: function* onSuccess(result) {
      yield put(uncensorFieldSuccess(result));
    },
    error: function* onError() {
      // clear the uncensored field to avoid displaying bad data
      yield put(clearUncensoredField());
    },
    errorToastMessage: `You do not have permission to uncensor ${payload.field}`,
  };

  yield call(makeSagaRequest, config);
}

export default function* watchUncensorField() {
  yield takeLatest(rootAction, uncensorField);
}
