// Models
import { EventActionTypes } from 'app/shared/events/models';

// Constants
import { initialState } from 'app/shared/events/reducer';

export const selectEventFilters = (state: RootState) => {
  return state.eventFilters || { ...initialState };
};

export const selectEvaluationLog = (state: RootState) => {
  return state.sliceEvents.evaluationLog;
};

export const selectActionEvents = (state: RootState) => {
  return (
    state.eventFilters.actionEvents || {
      action_events: [],
      count: 0,
    }
  );
};

export const selectActionEventsData = (state: RootState) => {
  return selectActionEvents(state).action_events;
};

export const selectActionEventsCount = (state: RootState) => {
  return state.eventFilters.actionEvents?.count || 0;
};

// Loading
export const selectEditEventLoading = (state: RootState) =>
  Boolean(state.loading[EventActionTypes.EDIT_EVENT]);

export const selectActionEventsLoading = (state: RootState) =>
  Boolean(state.loading[EventActionTypes.RETRIEVE_ACTION_EVENTS]);
