import {
  UncensorFieldPayload,
  CensorshipActionTypes,
  GetCensorshipConditionsPayload,
  GetCensorshipConditionsListResponse,
  CreateCensorshipPayload,
  CensorshipDetails,
  UpdateCensorshipPayload,
} from 'app/modules/censorship/models';
import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';

export const uncensorField = (payload: UncensorFieldPayload) =>
  createAction(CensorshipActionTypes.UNCENSOR_FIELD, payload);

export const uncensorFieldSuccess = (result) =>
  createAction(CensorshipActionTypes.UNCENSOR_FIELD_SUCCESS, result);

export const clearUncensoredField = () =>
  createAction(CensorshipActionTypes.CLEAR_UNCENSORED_FIELD);

// New Censorship Self-serve feature ////////////////

export const retrieveCensorshipConditions = (
  payload: GetCensorshipConditionsPayload,
) =>
  createAction(CensorshipActionTypes.RETRIEVE_CENSORSHIP_CONDITIONS, payload);

export const retrieveCensorshipConditionsSuccess = (
  result: GetCensorshipConditionsListResponse,
) =>
  createAction(
    CensorshipActionTypes.RETRIEVE_CENSORSHIP_CONDITIONS_SUCCESS,
    result,
  );

export const createCensorshipCondition = (
  payload: CreateCensorshipPayload,
  meta: () => void,
) => {
  return createAction(
    CensorshipActionTypes.CREATE_CENSORSHIP_CONDITION,
    payload,
    meta,
  );
};

export const createCensorshipConditionSuccess = (result: CensorshipDetails) => {
  return createAction(
    CensorshipActionTypes.CREATE_CENSORSHIP_CONDITION_SUCCESS,
    result,
  );
};

export const updateCensorshipCondition = (
  payload: UpdateCensorshipPayload,
  meta: () => void,
) => {
  return createAction(
    CensorshipActionTypes.UPDATE_ONE_CENSORSHIP_CONDITION,
    payload,
    meta,
  );
};

export const updateCensorshipConditionSuccess = (result: CensorshipDetails) => {
  return createAction(
    CensorshipActionTypes.UPDATE_ONE_CENSORSHIP_CONDITION_SUCCESS,
    result,
  );
};

export const deleteOneCensorshipCondition = (censorshipId: number) =>
  createAction(
    CensorshipActionTypes.DELETE_ONE_CENSORSHIP_CONDITION,
    censorshipId,
  );

export const deleteOneCensorshipConditionSuccess = (result) =>
  createAction(
    CensorshipActionTypes.DELETE_ONE_CENSORSHIP_CONDITION_SUCCESS,
    result,
  );

const CensorshipActionsList = {
  uncensorField,
  uncensorFieldSuccess,
  clearUncensoredField,
  retrieveCensorshipConditions,
  retrieveCensorshipConditionsSuccess,
  createCensorshipCondition,
  createCensorshipConditionSuccess,
  deleteOneCensorshipCondition,
  deleteOneCensorshipConditionSuccess,
  updateCensorshipCondition,
  updateCensorshipConditionSuccess,
};

export type CensorshipActions = ActionsUnion<typeof CensorshipActionsList>;
