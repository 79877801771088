import React, { ReactElement, ReactNode } from 'react';
import { connect } from 'react-redux';
import autoBindReact from 'auto-bind/react';

// Models
import { ObjectType } from 'app/modules/censorship/models';

// Actions
import { uncensorField as uncensorFieldAction } from 'app/modules/censorship/actions';

// Selectors
import {
  selectUncensoredField,
  selectUncensorFieldLoading,
} from 'app/modules/censorship/selectors';
import { selectUncensorFields } from 'app/modules/agentsOld/selectors';

// Styles
import { U21Chip } from 'app/shared/u21-ui/components';
import { IconEye, IconEyeOff } from '@u21/tabler-icons';
import { isEmpty } from 'lodash';

interface OwnProps {
  field: string; // field to censor/request to uncensor
  id: number; // id of the object i.e. alertId or entityId
  objectType: ObjectType;
  onEmptyRender?: string;

  // optionals
  formatValue?: (value: any) => ReactNode;
}

const mapStateToProps = (state: RootState) => {
  return {
    uncensorFields: selectUncensorFields(state),
    uncensoredField: selectUncensoredField(state),
    uncensorFieldLoading: selectUncensorFieldLoading(state),
  };
};

const mapDispatchToProps = {
  uncensorField: uncensorFieldAction,
};

type AllProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  OwnProps;

interface AllState {
  requestedValue: boolean;
  uncensored: boolean;
  value: string;
}

class CensoredField extends React.Component<AllProps, AllState> {
  constructor(props) {
    super(props);

    this.state = {
      requestedValue: false,
      uncensored: false,
      value: '',
    };

    autoBindReact(this);
  }

  componentDidUpdate(prevProps) {
    const { requestedValue } = this.state;
    const { field, uncensoredField, uncensorFieldLoading } = this.props;

    if (
      requestedValue &&
      prevProps.uncensorFieldLoading === true &&
      uncensorFieldLoading === false
    ) {
      if (field === uncensoredField.field) {
        this.setState({
          requestedValue: false,
          uncensored: true,
          value: uncensoredField.value,
        });
      }
    }
  }

  uncensorField(event) {
    event.preventDefault();
    const { requestedValue } = this.state;

    if (!requestedValue) {
      const { id, field, objectType, uncensorField } = this.props;

      uncensorField({
        object_id: id,
        object_type: objectType,
        field,
      });

      this.setState({
        requestedValue: true,
      });
    }

    this.setState({
      uncensored: true,
    });
  }

  censorField() {
    this.setState({
      uncensored: false,
    });
  }

  render() {
    const { formatValue, uncensorFields, field, onEmptyRender } = this.props;
    const { value, uncensored } = this.state;
    const handleCensoring = uncensored ? this.censorField : this.uncensorField;
    const valueToRender = formatValue ? formatValue(value) : value;
    const allowedToUncensor = uncensorFields.includes(field);
    const title = allowedToUncensor
      ? 'This field is censored. Click to uncensor.'
      : 'This field is censored. You do not have permission to uncensor it.';

    let uncensorIcon: ReactElement | undefined;
    if (allowedToUncensor) {
      uncensorIcon = uncensored ? <IconEyeOff /> : <IconEye />;
    }

    const displayValue =
      allowedToUncensor && uncensored ? valueToRender : '••••••';

    return isEmpty(displayValue) && onEmptyRender ? (
      onEmptyRender
    ) : (
      <div title={title}>
        <U21Chip
          onClick={allowedToUncensor ? handleCensoring : undefined}
          icon={uncensorIcon}
        >
          {`${displayValue}`}
        </U21Chip>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CensoredField);
