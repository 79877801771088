import {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

// Models
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

// Utils
import { isEqual } from 'lodash';

const setLocalStorageItem = <T extends NonNullable<JSONValue>>(
  key: LocalStorageKeys,
  item?: T,
  initialValue?: T,
) => {
  if (isEqual(item, initialValue)) {
    localStorage.removeItem(key);
  } else {
    const toSet = typeof item === 'string' ? item : JSON.stringify(item);
    localStorage.setItem(key, toSet);
  }
};

/**
 * Changes made to the object, to be stored in localStorage, will not cause your component to rerender.
 */
export const useLocalStorage = <T extends NonNullable<JSONValue>>(
  key: LocalStorageKeys,
  initialState?: T,
): [T, (value: T) => void] => {
  // Ref initialState so it does not cause rerender: initialState never changes
  const initialStateRef = useRef(initialState);

  // Parsed item from storage
  const parsedValue = useMemo(() => {
    const readFromLocalStorage = localStorage.getItem(key);
    if (readFromLocalStorage === null) {
      return initialStateRef.current;
    }

    // String type
    if (typeof initialStateRef.current === 'string') {
      return readFromLocalStorage;
    }

    // Number type
    if (typeof initialStateRef.current === 'number') {
      const storageNumber = parseFloat(readFromLocalStorage);
      return isNaN(storageNumber) ? initialStateRef.current : storageNumber;
    }

    // Boolean type parsing
    if (typeof initialStateRef.current === 'boolean') {
      if (readFromLocalStorage === 'true') {
        return true;
      } else if (readFromLocalStorage === 'false') {
        return false;
      }
      return initialStateRef.current;
    }

    // JSON type parsing
    try {
      return JSON.parse(readFromLocalStorage);
    } catch {}

    // Default return
    return initialStateRef.current || readFromLocalStorage;
  }, [key]) as T;

  // Storage Item in ref
  const storageItem = useRef(parsedValue);

  // Function to change ref
  const onItemChange = useCallback((value: T) => {
    storageItem.current = value;
  }, []);

  // Effect to write to localstorage
  useEffect(() => {
    // Visibility change handler
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setLocalStorageItem(key, storageItem.current, initialStateRef.current);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // initialStateRef.current stays same throughout lifecycle of a component
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setLocalStorageItem(key, storageItem.current, initialStateRef.current);
      // Removing event listeners is important otherwise they pile up in a session
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [key]);

  return [parsedValue, onItemChange];
};

/**
 * When object, to be stored in localStorage, needs to be the state of component. Changes to the object will cause rerender.
 */
export const useLocalStorageState = <
  T extends string | number | boolean | JSONValue[] | Record<string, JSONValue>,
>(
  key: LocalStorageKeys,
  initialState?: T,
): [T, Dispatch<SetStateAction<T>>] => {
  // Item from storage
  const [storageItem, setStorageItem] = useLocalStorage(key, initialState);

  // Items current state
  const [itemState, setItemState] = useState(storageItem);

  const handleSetItem: Dispatch<SetStateAction<T>> = useCallback(
    (valOrSetter) => {
      if (typeof valOrSetter === 'function') {
        setItemState((oldValue) => {
          const newValue = valOrSetter(oldValue);
          setStorageItem(newValue);
          return newValue;
        });
      } else {
        setItemState(valOrSetter);
        setStorageItem(valOrSetter);
      }
    },
    [setStorageItem],
  );

  return [itemState, handleSetItem];
};
