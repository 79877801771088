import { FilterOption } from 'app/modules/filters/models';
import { FILTER_TYPE } from 'app/modules/filters/constants';

export const WATCHLIST_FILTER_KEYS: Readonly<Record<string, string>> = {
  CREATED_AT: 'created_at',
  PROVIDER: 'provider',
  WATCHLIST_NAME: 'name',
  WATCHLIST_TYPE: 'type',
  WATCHLIST_NAMES: 'names',
};

export const WATCHLIST_FILTER_KEYS_SET = new Set<string>(
  Object.values(WATCHLIST_FILTER_KEYS),
);

export const WATCHLIST_FILTER_OPTIONS: FilterOption[] = [
  {
    key: WATCHLIST_FILTER_KEYS.CREATED_AT,
    label: 'Created at',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: WATCHLIST_FILTER_KEYS.PROVIDER,
    label: 'Provider',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: WATCHLIST_FILTER_KEYS.WATCHLIST_TYPE,
    label: 'Watchlist type',
    type: FILTER_TYPE.TEXT,
  },
];

export const ADVERSE_MEDIA_FILTER_KEY: Readonly<Record<string, string>> = {
  CATEGORY: 'category',
};

export const ADVERSE_MEDIA_FILTER_KEYS_SET = new Set<string>(
  Object.values(ADVERSE_MEDIA_FILTER_KEY),
);

export const ADVERSE_MEDIA_FILTER_OPTIONS: FilterOption[] = [];
