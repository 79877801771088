import entitiesSearch from 'app/modules/search/sagas/entitiesSearch';
import rulesSearch from 'app/modules/search/sagas/rulesSearch';
import alertsSearch from 'app/modules/search/sagas/alertsSearch';
import eventsSearch from 'app/modules/search/sagas/eventsSearch';
import teamsSearch from 'app/modules/search/sagas/teamsSearch';
import censorshipFieldsSearch from 'app/modules/search/sagas/censorshipFieldsSearch';

const sagaRegistry = [
  entitiesSearch,
  rulesSearch,
  alertsSearch,
  eventsSearch,
  teamsSearch,
  censorshipFieldsSearch,
];

export default sagaRegistry;
