import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

// Models
import { Filter } from 'app/modules/filters/models';

// Utils
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import { getValidFilters } from 'app/modules/filters/utils';

// Constants
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import {
  ADVERSE_MEDIA_FILTER_OPTIONS,
  WATCHLIST_FILTER_OPTIONS,
} from 'app/modules/watchlists/filters';

const WATCHLISTS_NAME = 'watchlists';

interface WatchlistsState {
  watchlistFilters: Filter[];
  adverseMediaFilters: Filter[];
}

const initialState: Readonly<WatchlistsState> = {
  watchlistFilters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.WATCHLIST_FILTERS),
    WATCHLIST_FILTER_OPTIONS,
  ),
  adverseMediaFilters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.ADVERSE_MEDIA_FILTERS),
    ADVERSE_MEDIA_FILTER_OPTIONS,
  ),
};

export const setWatchlistFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${WATCHLISTS_NAME}/SET_WATCHLIST_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.WATCHLIST_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setAdverseMediaFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${WATCHLISTS_NAME}/SET_ADVERSE_MEDIA_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.ADVERSE_MEDIA_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const watchlistsSlice = u21CreateSlice({
  name: WATCHLISTS_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setWatchlistFilters.fulfilled, (draft, action) => {
        draft.watchlistFilters = action.payload;
      })
      .addCase(setAdverseMediaFilters.fulfilled, (draft, action) => {
        draft.adverseMediaFilters = action.payload;
      });
  },
});

export const WATCHLISTS_SLICE_NAME = watchlistsSlice.name;
export default watchlistsSlice.reducer;
