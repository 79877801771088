import { destroy, post, put } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';

// Models
import {
  CreateCensorshipPayload,
  GetCensorshipConditionsPayload,
  UncensorFieldPayload,
  UpdateCensorshipPayload,
} from 'app/modules/censorship/models';

export const uncensorField = (payload: UncensorFieldPayload) => {
  return post(routes.patronus.censorshipUncensorField, payload);
};

// New Censorship Self-serve feature  ////////////////////

export const getCensorshipConditions = (
  payload: GetCensorshipConditionsPayload,
) => {
  return post(routes.patronus.censorshipList, payload);
};

export const createCensorshipCondition = (payload: CreateCensorshipPayload) => {
  return post(routes.patronus.censorshipCreate, payload);
};

export const updateOneCensorshipCondition = (
  payload: UpdateCensorshipPayload,
) => {
  return put(routes.patronus.censorshipUpdate, payload);
};

export const deleteOneCensorshipCondition = (censorshipId: number) =>
  destroy(
    routes.patronus.deleteOneCensorshipCondition.replace(
      ':id',
      String(censorshipId),
    ),
  );
