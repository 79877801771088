// Types
import { ShortTxnResponse } from 'app/modules/transactions/types';

// Models
import { EventHistogram } from 'app/shared/types/histogram';
import {
  EventFiltersState,
  ActionEventDetails,
} from 'app/shared/events/models';

export enum TransactionsActionTypes {
  SET_ORG_TRANSACTION_FILTERS = '@@transactions/SET_ORG_TRANSACTION_FILTERS',

  RETRIEVE_ORG_TRANSACTION = '@@transactions/RETRIEVE_ORG_TRANSACTION',
  RETRIEVE_ORG_TRANSACTION_SUCCESS = '@@transactions/RETRIEVE_ORG_TRANSACTION_SUCCESS',

  RETRIEVE_ACTION_EVENT = '@@transactions/RETRIEVE_ACTION_EVENT',
  RETRIEVE_ACTION_EVENT_SUCCESS = '@@transactions/RETRIEVE_ACTION_EVENT_SUCCESS',
}

export interface HistogramData extends EventHistogram {
  updated_at?: string;
}

export interface PaginatedTxns {
  count: number;
  txns: ShortTxnResponse[];
}
export interface TransactionsState {
  transactionFilters: EventFiltersState;
  actionEvent: ActionEventDetails;
}
