import { useQuery } from '@tanstack/react-query';
import { get } from 'app/shared/utils/fetchr';
import { AdverseMediaRiskCategoryResponse } from 'app/modules/watchlists/responses';
import { WATCHLIST_QUERY_KEYS } from 'app/modules/watchlists/queries/keys';

const getAdverseMediaRiskCategories = (
  alertId: number,
): Promise<AdverseMediaRiskCategoryResponse> =>
  get<AdverseMediaRiskCategoryResponse>(
    `/watchlists/alerts/${alertId.toString()}/risk-categories/list`,
  );

export const useGetAdverseMediaRiskCategories = (alertId: number) => {
  return useQuery({
    queryKey: WATCHLIST_QUERY_KEYS.getAlertAdverseMediaRiskCategories(alertId),
    queryFn: () => getAdverseMediaRiskCategories(alertId),
    meta: { errorMessage: 'Failed to get risk categories.' },
  });
};
