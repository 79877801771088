import { PaginationPayload } from 'app/shared/pagination/models';

const BASE = ['orgs'] as const;

export const ORG_MANAGEMENT_QUERY_KEYS = {
  getOrgs: () => [...BASE, 'orgs'] as const,
  getOrg: (id: number) => [...BASE, 'org', id] as const,
  getDefaultRules: () => [...BASE, 'default-rules'] as const,
  getOrgSettings: (id: number) => [...BASE, 'org-settings', id] as const,
  getOrgDatafileProcessingSetting: (orgId?: number) =>
    [...BASE, 'orgDatafileProcessingSettings', orgId] as const,
  getOrgChildren: (orgId: number) =>
    [...BASE, 'getOrgChildren', orgId] as const,
  getChildOrgTeams: (orgId: number) =>
    [...BASE, 'getChildOrgTeams', orgId] as const,
  getOrgAgents: (orgId: number, payload?: PaginationPayload) => {
    if (payload) {
      return [...BASE, 'getOrgAgents', orgId, payload] as const;
    }
    return [...BASE, 'getOrgAgents', orgId] as const;
  },
  orgDatafileIntegrationSetup: (orgId?: number) =>
    [...BASE, 'orgDatafileIntegrationSetup', orgId] as const,
};
