import { post, get } from 'app/shared/utils/fetchr';
import { DetectionModelsNewMetricPayload } from 'app/modules/detectionModels/types/requests';
import {
  DetectionModelExecutionResponse,
  DetectionModelNewMetricResponse,
} from 'app/modules/detectionModels/types/responses';
import { MetricNameEnum } from 'app/modules/detectionModels/constants';
import { PaginationPayload } from 'app/shared/pagination/models';
import {
  DeactivateRuleResponse,
  PaginatedAlerts,
  PaginatedSchedules,
} from 'app/modules/rules/models';

import {
  DetectionModelValidationResult,
  DetectionModelsResponse,
} from 'app/modules/rules/types/responses';

const DETECTION_MODELS = 'detection-models';
const RULES = 'rules';
export const retrieveDetectionModelNewMetric = (
  ruleId: number,
  metricName: MetricNameEnum,
  payload: DetectionModelsNewMetricPayload,
): Promise<DetectionModelNewMetricResponse> =>
  post(`/${DETECTION_MODELS}/${ruleId}/metric/${metricName}`, payload);

export const deactivateDetectionModel = (
  ruleId: number,
): Promise<DeactivateRuleResponse> => post(`/${RULES}/deactivate/${ruleId}`);

export const retrieveDetectionModelExecutions = (
  ruleId: number,
  payload: PaginationPayload,
): Promise<DetectionModelExecutionResponse> =>
  post(`/${RULES}/${ruleId}/paginated-executions`, payload);

export const retrieveDetectionModelGeneratedAlerts = (
  payload: { id: number } & PaginationPayload,
): Promise<PaginatedAlerts> => {
  const { id, ...details } = payload;
  const path = `/${RULES}/paginated-alerts/${id}`;
  return post(path, details);
};

export const retrieveDetectionModelValidationResults = (payload: {
  id: number;
}): Promise<Array<DetectionModelValidationResult>> => {
  const { id } = payload;
  return get(`/${DETECTION_MODELS}/${id}/validation-results`);
};

export const retrieveDetectionModelExecutionSchedules = (
  ruleId: number,
  executionId: number,
  payload: PaginationPayload,
): Promise<PaginatedSchedules> =>
  post(
    `/${RULES}/${ruleId}/paginated-executions/${executionId}/schedules`,
    payload,
  );

export const retrieveDetectionModelExecutionAlerts = (
  ruleId: number,
  executionId: number,
  payload: PaginationPayload,
): Promise<PaginatedAlerts> =>
  post(
    `/${RULES}/${ruleId}/paginated-executions/${executionId}/alerts`,
    payload,
  );

export const retrieveDetectionModels = (): Promise<DetectionModelsResponse> =>
  get<DetectionModelsResponse>(`/${DETECTION_MODELS}`, [
    { key: 'short', value: 'true' },
  ]);
