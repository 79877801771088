import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SearchActionTypes } from 'app/modules/search/models';

// Actions
import {
  censorshipFieldsSearch as censorshipFieldsSearchAction,
  censorshipFieldsSearchSuccess,
} from 'app/modules/search/actions';

// API
import { censorshipFieldsSearch as censorshipFieldsSearchApi } from 'app/shared/api/search';

const rootAction = SearchActionTypes.CENSORSHIP_FIELDS_SEARCH;
function* censorshipFieldsSearch({
  payload,
}: ReturnType<typeof censorshipFieldsSearchAction>) {
  const config = {
    rootAction,
    errorToastMessage: `There was an error searching fields. Please try again`,
    request: call(censorshipFieldsSearchApi, payload),
    success: function* onSuccess(result) {
      yield put(censorshipFieldsSearchSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchCensorshipFieldsSearch() {
  yield takeEvery(rootAction, censorshipFieldsSearch);
}
