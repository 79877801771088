import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CensorshipActionTypes } from 'app/modules/censorship/models';

// Actions
import {
  createCensorshipCondition as createCensorshipConditionAction,
  createCensorshipConditionSuccess,
} from 'app/modules/censorship/actions';

// API
import { createCensorshipCondition as createCensorshipConditionApi } from 'app/shared/api/censorship';
import { sendSuccessToast } from 'app/shared/toasts/actions';

const rootAction = CensorshipActionTypes.CREATE_CENSORSHIP_CONDITION;
function* createCensorshipCondition({
  payload,
  meta,
}: ReturnType<typeof createCensorshipConditionAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong, unable to create new censorship condition',
    request: call(createCensorshipConditionApi, payload),
    success: function* onSuccess(result) {
      meta();
      yield put(createCensorshipConditionSuccess(result));
      yield put(sendSuccessToast(`Censorship condition successfully created`));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchCreateCensorshipCondition() {
  yield takeLatest(rootAction, createCensorshipCondition);
}
