import { useTheme } from '@mui/styles';

import { U21Chip } from 'app/shared/u21-ui/components';
import {
  IconShieldCheck,
  IconShieldDown,
  IconShieldQuestion,
} from '@u21/tabler-icons';
import { ArticleCategory } from 'app/modules/watchlists/models';
import { AdverseMediaRiskClassification } from 'app/modules/watchlists/constants';

interface OwnProps {
  category: ArticleCategory;
}

export const AdverseMediaMatchCategoryChip = ({ category }: OwnProps) => {
  const { palette } = useTheme();

  if (category.classification === AdverseMediaRiskClassification.HIGH) {
    return (
      <U21Chip
        icon={<IconShieldCheck color={palette.primary.main} />}
        tooltip="High confidence"
      >
        {category.category}
      </U21Chip>
    );
  }

  if (category.classification === AdverseMediaRiskClassification.MODERATE) {
    return (
      <U21Chip icon={<IconShieldQuestion />} tooltip="Moderate confidence">
        {category.category}
      </U21Chip>
    );
  }

  if (category.classification === AdverseMediaRiskClassification.LOW) {
    return (
      <U21Chip
        icon={<IconShieldDown color={palette.error.main} />}
        tooltip="Low confidence"
      >
        {category.category}
      </U21Chip>
    );
  }

  return null;
};
