import { IconChevronUp, IconChevronsUp, IconMinus } from '@u21/tabler-icons';
import {
  U21HelpTooltip,
  U21TableBodyCellProps,
  U21TableColumn,
  U21TableColumnTypes,
  U21Typography,
  U21NoValue,
} from 'app/shared/u21-ui/components';
import {
  AdverseMediaRiskCategory,
  WatchlistResult,
} from 'app/modules/watchlists/models';
import pluralize from 'pluralize';

export type WatchlistTableColumn = U21TableColumn<WatchlistResult, any>;

const ID_COLUMN: WatchlistTableColumn = {
  id: 'id',
  Header: 'ID',
  accessor: (row) => row.id,
};

const CREATED_AT_COLUMN: WatchlistTableColumn = {
  id: 'created_at',
  Header: 'Created at',
  accessor: (row) => row.created_at,
  type: U21TableColumnTypes.DATETIME,
};

const WATCHLIST_TYPE_COLUMN: WatchlistTableColumn = {
  id: 'type',
  Header: 'Watchlist type',
  accessor: (row) => row.type,
  type: U21TableColumnTypes.LABEL,
};

const WATCHLIST_NAMES_COLUMN: WatchlistTableColumn = {
  id: 'names',
  Header: 'Watchlist names',
  accessor: (row) => row.names,
  type: U21TableColumnTypes.LIST,
  disableSortBy: true,
};

const PROVIDER_COLUMN: WatchlistTableColumn = {
  id: 'provider',
  Header: 'Provider',
  accessor: (row) => row.provider,
  type: U21TableColumnTypes.LABEL,
};

export const WATCHLIST_COLUMN_CONFIG = {
  [ID_COLUMN.id]: ID_COLUMN,
  [CREATED_AT_COLUMN.id]: CREATED_AT_COLUMN,
  [WATCHLIST_TYPE_COLUMN.id]: WATCHLIST_TYPE_COLUMN,
  [WATCHLIST_NAMES_COLUMN.id]: WATCHLIST_NAMES_COLUMN,
  [PROVIDER_COLUMN.id]: PROVIDER_COLUMN,
};

export type RiskCategoriesTableColumn = U21TableColumn<
  AdverseMediaRiskCategory,
  any
>;

export const CATEGORY_COLUMN: RiskCategoriesTableColumn = {
  id: 'category',
  Header: 'Category',
  accessor: (row) => row.category,
};

const RISK_SCORE_COLUMN: RiskCategoriesTableColumn = {
  id: 'risk_score',
  Header: 'Risk score',
  accessor: (row) => row.risk_score,
  Cell: (props: U21TableBodyCellProps<AdverseMediaRiskCategory>) => {
    const {
      row: { risk_score: riskScore },
    } = props;

    if (riskScore === null) {
      return <U21NoValue />;
    }

    return (
      <U21Typography color="text.secondary" variant="body2">
        {riskScore}%
      </U21Typography>
    );
  },
  sortType: 'number',
};

const RULE_THRESHOLD_COLUMN: RiskCategoriesTableColumn = {
  id: 'rule_threshold',
  Header: () => (
    <>
      <U21Typography variant="subtitle2" color="text.secondary">
        Rule threshold
      </U21Typography>
      <U21HelpTooltip
        help={
          <>
            Rule threshold:
            <br />
            Shows whether the risk score for the category is higher or lower
            than the threshold set in the rule, if applicable.
          </>
        }
      />
    </>
  ),
  accessor: (row) => row.rule_threshold,
  Cell: (props: U21TableBodyCellProps<AdverseMediaRiskCategory>) => {
    const {
      row: { rule_threshold: threshold },
    } = props;

    if (threshold === null) {
      return <U21NoValue />;
    }

    if (threshold <= 0) {
      return (
        <U21Typography
          icon={<IconMinus />}
          variant="body2"
          color="text.secondary"
        >
          {Math.abs(threshold)}% lower
        </U21Typography>
      );
    }
    return (
      <U21Typography
        icon={threshold < 10 ? <IconChevronUp /> : <IconChevronsUp />}
        iconColor={threshold < 10 ? 'warning' : 'error'}
        variant="body2"
        color="text.secondary"
      >
        {threshold}% higher
      </U21Typography>
    );
  },
  sortType: 'number',
};

const ARTICLES_COLUMN: RiskCategoriesTableColumn = {
  id: 'article_count',
  Header: 'Articles',
  accessor: (row) => row.article_count,
  Cell: (props: U21TableBodyCellProps<AdverseMediaRiskCategory>) => {
    const {
      row: { article_count: articleCount },
    } = props;

    return (
      <U21Typography color="text.secondary" variant="body2">
        {articleCount} {pluralize('article', articleCount)}
      </U21Typography>
    );
  },
  sortType: 'number',
};

export const RISK_CATEGORIES_COLUMN_CONFIG = [
  CATEGORY_COLUMN,
  RISK_SCORE_COLUMN,
  RULE_THRESHOLD_COLUMN,
  ARTICLES_COLUMN,
];
