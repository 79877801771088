import { ReactNode } from 'react';

// Components
import { Sidebar } from 'app/modules/sidebar/components/Sidebar';
import TopBar from 'app/modules/navigator/components/TopBar';
import Leftnav from 'app/modules/navigator/components/Leftnav';

// Style
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'LeftNav TopBar TopBar'
    'LeftNav Content RightSlider';
  height: 100vh;
  width: 100vw;
  /* do not use fixed height in pdf exports */
  @media print {
    height: auto;
    width: auto;
  }
`;

const Content = styled.div`
  grid-area: Content;
  overflow-y: scroll;
  overflow-x: hidden;
`;

interface Props {
  children: ReactNode;
}

const GridLayout = ({ children }: Props) => {
  return (
    <Container>
      <Leftnav />
      <TopBar />
      <Sidebar />
      <Content>{children}</Content>
    </Container>
  );
};

export default GridLayout;
