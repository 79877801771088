import {
  DataSettingsKeyType,
  FormattedData,
  OrgDataSettingsConfig,
} from 'app/modules/dataSettings/responses';
import { FieldInfo } from 'app/modules/watchlists/models';

// Get watchlist name
export const getWatchlistResultName = (
  watchlistConfig: OrgDataSettingsConfig[][] | undefined,
  formattedData: FormattedData | undefined,
): string => {
  let watchlistFullName = '';
  let watchlistName = '';

  if (formattedData !== undefined) {
    watchlistConfig?.forEach((group) => {
      group.forEach((data) => {
        if (data !== null && formattedData[data.id].formatted_value !== '') {
          if (
            data.user_facing_label?.toLowerCase().includes('name') ||
            (data.key_type === DataSettingsKeyType.CUSTOM &&
              data.key_path?.includes('name'))
          ) {
            watchlistName = formattedData[data.id].formatted_value;
          }

          if (
            data.key_type === DataSettingsKeyType.CUSTOM &&
            data.key_path?.includes('full_name')
          ) {
            watchlistFullName = formattedData[data.id].formatted_value;
          }
        }
      });
    });
  }
  return watchlistFullName || watchlistName; // We want to prioritise full name if possible
};

// Resize config arr
export const resizeConfigArr = (
  config: OrgDataSettingsConfig[],
  matchingConfig: OrgDataSettingsConfig[],
): OrgDataSettingsConfig[] => {
  const maxSize = Math.max(config.length, matchingConfig.length);
  const arr = Array(maxSize).fill(null);
  return arr.map((x, i) => {
    return config[i] ?? null;
  });
};

// This function is need to ensure that the UI is rerendered
export const deepShallowCopyFieldInfo = (fieldInfos: FieldInfo): FieldInfo => ({
  fields: [...fieldInfos.fields],
  entityUnusedFields: [...fieldInfos.entityUnusedFields],
  watchlistUnusedFields: [...fieldInfos.watchlistUnusedFields],
  formattedData: { ...fieldInfos.formattedData },
});
