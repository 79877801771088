import styled from 'styled-components';

import {
  U21Button,
  U21Card,
  U21ExternalLinkModal,
  U21ShowMoreText,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconExternalLink } from '@u21/tabler-icons';
import { AdverseMediaMatchCategoryChip } from 'app/modules/watchlists/components/AdverseMediaMatchCategoryChip';
import { AdverseMediaArticle } from 'app/modules/watchlists/models';

interface OwnProps {
  article: AdverseMediaArticle;
}

export const AdverseMediaMatchCard = ({ article }: OwnProps) => {
  return (
    <StyledU21Card
      title={article.title}
      shaded
      subheader={
        <U21Spacer horizontal>
          <StyledU21Typography variant="body2" color="text.secondary">
            {article.source}
          </StyledU21Typography>
          <U21ExternalLinkModal url={article.url}>
            {({ onClick }) => (
              <StyledU21Button
                color="primary"
                variant="text"
                onClick={(event) => {
                  onClick();
                  event.stopPropagation();
                }}
                endIcon={<IconExternalLink />}
              >
                <OverflowText>{article.url}</OverflowText>
              </StyledU21Button>
            )}
          </U21ExternalLinkModal>
        </U21Spacer>
      }
    >
      <U21Spacer spacing={2}>
        <U21ShowMoreText value={article.snippet} limit={400} variant="body2" />
        {Boolean(article.categories?.length) && (
          <U21Spacer horizontal>
            {article.categories.map((category) => (
              <AdverseMediaMatchCategoryChip
                key={`${article.title} ${category.category}`}
                category={category}
              />
            ))}
          </U21Spacer>
        )}
      </U21Spacer>
    </StyledU21Card>
  );
};

const StyledU21Card = styled(U21Card)`
  .MuiCardHeader-content {
    white-space: nowrap;
    overflow: hidden;
  }
`;

const StyledU21Button = styled(U21Button)`
  overflow: hidden;
`;

const StyledU21Typography = styled(U21Typography)`
  white-space: nowrap;
`;

const OverflowText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
