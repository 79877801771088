import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CensorshipActionTypes } from 'app/modules/censorship/models';

// Actions
import {
  updateCensorshipCondition as updateCensorshipConditionAction,
  updateCensorshipConditionSuccess,
} from 'app/modules/censorship/actions';

// API
import { updateOneCensorshipCondition as updateCensorshipConditionApi } from 'app/shared/api/censorship';
import { sendSuccessToast } from 'app/shared/toasts/actions';

const rootAction = CensorshipActionTypes.UPDATE_ONE_CENSORSHIP_CONDITION;
function* updateCensorshipCondition({
  payload,
  meta,
}: ReturnType<typeof updateCensorshipConditionAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong, unable to update the censorship condition',
    request: call(updateCensorshipConditionApi, payload),
    success: function* onSuccess(result) {
      meta();
      yield put(updateCensorshipConditionSuccess(result));
      yield put(sendSuccessToast(`Censorship condition successfully updated`));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchUpdateCensorshipCondition() {
  yield takeLatest(rootAction, updateCensorshipCondition);
}
