// Models
import { TableConfig } from 'app/shared/pagination/models';

export const CENSORSHIP_CONDITIONS_TABLE_ACTIONS: TableConfig[] = [
  {
    key: '_action',
    label: '',
    width: 50,
    type: '_action',
  },
];

export const FULL_NAME_OPTION = {
  text: 'Full name',
  value: 'name',
};

export const CENSORSHIP_NAME_FIELDS = new Set([
  'first_name',
  'middle_name',
  'last_name',
]);

export const DEFAULT_CENSORSHIP_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'name',
    type: 'title',
    label: 'Name',
  },
  {
    key: 'target_type',
    type: 'label',
    label: 'Target type',
  },
  { key: 'targets', type: 'text', label: 'Targets', sortable: false },
  { key: 'fields', type: 'text', label: 'Censored fields', sortable: false },
  {
    key: 'uncensor',
    type: 'label',
    label: 'Can be uncensored',
    sortable: false,
  },
  {
    key: 'created_by_full_name',
    type: 'text',
    label: 'Creator',
  },
  { key: 'created_at', type: 'datetime', label: 'Created on' },
];
