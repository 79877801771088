import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';

// Models
import {
  SearchActionTypes,
  SearchRequest,
  EntitySearchResponse,
  AlertsSearchResponse,
  EventsSearchResponse,
  RulesSearchResponse,
  TeamsSearchResponse,
  EntitySearchRequest,
} from 'app/modules/search/models';

export const entitiesSearch = (payload: EntitySearchRequest) =>
  createAction(SearchActionTypes.ENTITIES_SEARCH, payload);

export const entitiesSearchSuccess = (response: EntitySearchResponse) =>
  createAction(SearchActionTypes.ENTITIES_SEARCH_SUCCESS, response);

export const teamsSearch = (payload: SearchRequest) =>
  createAction(SearchActionTypes.TEAMS_SEARCH, payload);

export const teamsSearchSuccess = (response: TeamsSearchResponse) =>
  createAction(SearchActionTypes.TEAMS_SEARCH_SUCCESS, response);

export const censorshipFieldsSearch = (payload: SearchRequest) =>
  createAction(SearchActionTypes.CENSORSHIP_FIELDS_SEARCH, payload);

export const censorshipFieldsSearchSuccess = (response: TeamsSearchResponse) =>
  createAction(SearchActionTypes.CENSORSHIP_FIELDS_SEARCH_SUCCESS, response);

export const rulesSearch = (payload: SearchRequest) =>
  createAction(SearchActionTypes.RULES_SEARCH, payload);

export const rulesSearchSuccess = (response: RulesSearchResponse) =>
  createAction(SearchActionTypes.RULES_SEARCH_SUCCESS, response);

export const alertsSearch = (payload: SearchRequest) =>
  createAction(SearchActionTypes.ALERTS_SEARCH, payload);

export const alertsSearchSuccess = (response: AlertsSearchResponse) =>
  createAction(SearchActionTypes.ALERTS_SEARCH_SUCCESS, response);

export const eventsSearch = (payload: SearchRequest) =>
  createAction(SearchActionTypes.EVENTS_SEARCH, payload);

export const eventsSearchSuccess = (response: EventsSearchResponse) =>
  createAction(SearchActionTypes.EVENTS_SEARCH_SUCCESS, response);

const SearchReduxActions = {
  entitiesSearchSuccess,
  rulesSearchSuccess,
  alertsSearchSuccess,
  eventsSearchSuccess,
  teamsSearchSuccess,
  censorshipFieldsSearchSuccess,
};

export type SearchActions = ActionsUnion<typeof SearchReduxActions>;
