import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CensorshipActionTypes } from 'app/modules/censorship/models';

// Actions
import {
  deleteOneCensorshipCondition as deleteOneCensorshipConditionAction,
  deleteOneCensorshipConditionSuccess,
} from 'app/modules/censorship/actions';

// API
import { deleteOneCensorshipCondition as API } from 'app/shared/api/censorship';

const rootAction = CensorshipActionTypes.DELETE_ONE_CENSORSHIP_CONDITION;
function* deleteOneCensorshipCondition({
  payload,
}: ReturnType<typeof deleteOneCensorshipConditionAction>) {
  const config = {
    rootAction,
    request: call(API, payload),
    errorToastMessage: 'Error deleting censorship condition',
    success: function* onSuccess() {
      yield put(deleteOneCensorshipConditionSuccess(payload));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchDeleteOneCensorshipCondition() {
  yield takeLatest(rootAction, deleteOneCensorshipCondition);
}
