// APIs
import {
  bulkActivate,
  bulkDeactivate,
  downloadFileImportErrorFile,
  editRule,
  exportRules,
  importRules,
} from 'app/shared/api/rules';
import {
  activateDetectionModel,
  activateSynchronousDetectionModel,
  retrieveDetectionModel,
  retrieveDetectionModelValidation,
  retrieveScenarioConfigs,
  revalidateDetectionModel,
  testRule,
  validateDetectionModel,
} from 'app/shared/api/detectionModels';
import {
  deactivateDetectionModel,
  retrieveDetectionModelValidationResults,
  retrieveDetectionModels,
} from 'app/modules/detectionModels/api';
import {
  retrieveAlertActionEvents,
  retrieveAlertInstruments,
} from 'app/shared/api/alerts';

// Models
import {
  ActivateDetectionModelPayload,
  ActivateSynchronousDetectionModelPayload,
  BulkActivateDetectionModelPayload,
  DetectionModel,
  DetectionModelValidation,
  ScenarioConfigs,
  ValidateModelPayload,
} from 'app/modules/detectionModels/models';
import { Filter } from 'app/modules/filters/models';
import {
  ImportRuleErrorFilePayload,
  RuleTestPayload,
} from 'app/modules/detectionModels/types/requests';
import {
  BulkDeactivatePayload,
  DeactivateRuleResponse,
  EditRulePayload,
  RuleValidationAlertActionEventsResponse,
} from 'app/modules/rules/models';
import {
  EMPTY_DETECTION_MODEL,
  EMPTY_MODEL_VALIDATION,
} from 'app/modules/detectionModels/constants';
import {
  BulkDeactivateRulesResponse,
  RuleImportResponse,
} from 'app/modules/detectionModels/types/responses';
import { PaginationPayload } from 'app/shared/pagination/models';

// Actions
import {
  sendErrorToast,
  sendSuccessToast,
  sendToast,
} from 'app/shared/toasts/actions';
import { retrieveScenarioConfigsSuccess } from 'app/modules/detectionModels/actions';
import {
  retrieveRulesValidationAlertInstrumentsSuccess,
  retrieveRuleValidationAlertActionEventsSuccess,
} from 'app/modules/rules/actions';

// Utils
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { History } from 'history';
import {
  DetectionModelCount,
  DetectionModelValidationResult,
  DetectionModelsResponse,
} from 'app/modules/rules/types/responses';
import { cloneDeep } from 'lodash';
import { transformConditionOperatorsForBE } from 'app/modules/detectionModels/helpers';
import { getValidFilters } from 'app/modules/filters/utils';
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';

import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import {
  getDefaultLiveRuleFilters,
  SHADOW_MODEL_FILTERS,
  VALIDATING_MODEL_FILTERS,
} from 'app/modules/detectionModels/filters';
import { toIrSchema } from 'app/modules/rulesAdvanced/helper';

interface DetectionModelsState {
  loadingRetrieveDetectionModels: boolean;
  loadingRetrieveDetectionModel: boolean;
  loadingRetrieveDetectionModelValidation: boolean;
  loadingRetrieveDetectionModelValidationResults: boolean;
  loadingDetectionModelTestResults: boolean;
  loadingValidateDetectionModel: boolean;
  loadingRevalidateDetectionModel: boolean;
  loadingScenarioConfigs: boolean;
  loadingEditDetectionModel: boolean;
  loadingDeactivateDetectionModel: boolean;
  loadingRetrieveRuleValidationAlertsInstruments: boolean;
  detectionModelTestResults: DetectionModelTestResult;
  count: DetectionModelCount | null;
  retrievedDetectionModel: DetectionModel;
  retrievedDetectionModelValidationResults: Array<DetectionModelValidationResult>;
  detectionModelValidation: DetectionModelValidation;
  scenarioConfigs: ScenarioConfigs | null;
  liveRuleFilters: Filter[];
  validatingRuleFilters: Filter[];
  shadowRuleFilters: Filter[];
}

export interface DetectionModelTestResult {
  fact_results: Array<DetectionModelFactResult>;
  failed_condition_results: Array<TestResultCondition>;
  _error_result?: string;
}

export type FactResultType = number | string | boolean | undefined;

export interface DetectionModelFactResult {
  string: FactResultType;
}

interface TestResultCondition {
  string: string;
}

const DETECTION_MODELS = 'detection-models';
export const initialState: DetectionModelsState = {
  loadingRetrieveDetectionModels: false,
  loadingRetrieveDetectionModel: false,
  loadingRetrieveDetectionModelValidation: false,
  loadingRetrieveDetectionModelValidationResults: false,
  loadingDetectionModelTestResults: false,
  loadingValidateDetectionModel: false,
  loadingRevalidateDetectionModel: false,
  loadingScenarioConfigs: false,
  loadingEditDetectionModel: false,
  loadingDeactivateDetectionModel: false,
  loadingRetrieveRuleValidationAlertsInstruments: false,
  detectionModelTestResults: {
    fact_results: [],
    failed_condition_results: [],
  },
  count: null,
  retrievedDetectionModel: EMPTY_DETECTION_MODEL, // retrieved from BE
  retrievedDetectionModelValidationResults: [],
  detectionModelValidation: EMPTY_MODEL_VALIDATION,
  scenarioConfigs: null,
  liveRuleFilters: getDefaultLiveRuleFilters(),
  validatingRuleFilters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.VALIDATING_RULE_FILTERS),
    VALIDATING_MODEL_FILTERS,
  ),
  shadowRuleFilters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.SHADOW_RULE_FILTERS),
    SHADOW_MODEL_FILTERS,
  ),
};

export const bulkActivateRulesThunk = u21CreateAsyncThunk<
  BulkActivateDetectionModelPayload,
  void
>(`${DETECTION_MODELS}/BULK_ACTIVATE_RULES`, async (payload, { dispatch }) => {
  try {
    const response = await bulkActivate(payload);
    dispatch(
      sendToast({
        message: `Successfully activated ${response.length} rules.`,
        type: 'Success',
        position: 'Top',
      }),
    );
  } catch (e) {
    dispatch(sendErrorToast('Unable to activate rules.'));
    throw e;
  }
});

export const bulkDeactivateRulesThunk = u21CreateAsyncThunk<
  BulkDeactivatePayload,
  BulkDeactivateRulesResponse
>(
  `${DETECTION_MODELS}/BULK_DEACTIVATE_RULES`,
  async (payload, { dispatch }) => {
    try {
      const response = await bulkDeactivate(payload);
      dispatch(
        sendToast({
          message: `Successfully deactivated ${response.length} rules.`,
          type: 'Success',
          position: 'Top',
        }),
      );
      return response;
    } catch (e) {
      dispatch(sendErrorToast('Unable to deactivate rules.'));
      throw e;
    }
  },
);
export const testDetectionModelsThunk = u21CreateAsyncThunk<
  RuleTestPayload,
  DetectionModelTestResult
>(
  `${DETECTION_MODELS}/TEST_DETECTION_MODEL`,
  async (payload: RuleTestPayload, { dispatch }) => {
    try {
      return await testRule(payload);
    } catch (e) {
      dispatch(sendErrorToast('Error testing rule.'));
      throw e;
    }
  },
);

export const exportRulesThunk = u21CreateAsyncThunk<number[], void>(
  `${DETECTION_MODELS}/EXPORT_RULES`,
  async (payload: number[], { dispatch }) => {
    try {
      await exportRules(payload, `rule_export_${Date.now().toString()}.txt`);
      dispatch(
        sendToast({
          message: `Successfully exported rules. Check your download folder for the export file.`,
          type: 'Success',
          position: 'Top',
        }),
      );
    } catch (e) {
      dispatch(sendErrorToast('Unable to generate rules export file.'));
    }
  },
);

export const importRulesThunk = u21CreateAsyncThunk<string, RuleImportResponse>(
  `${DETECTION_MODELS}/IMPORT_RULES`,
  async (payload: string, { dispatch }) => {
    try {
      return await importRules(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to import rules.'));
      return { error: '', failure: [], success: [] };
    }
  },
);

export const downloadErrorFileForRuleImport = u21CreateAsyncThunk<
  ImportRuleErrorFilePayload,
  void
>(
  `${DETECTION_MODELS}/RULE_IMPORT_ERROR_FILE`,
  async (payload: ImportRuleErrorFilePayload, { dispatch }) => {
    try {
      await downloadFileImportErrorFile(
        payload.failure,
        `${payload.fileName}_error_report.xlsx`,
      );
    } catch (e) {
      dispatch(sendErrorToast('Unable to download rules import error file.'));
    }
  },
);

export const retrieveScenarioConfigsThunk = u21CreateAsyncThunk<
  void,
  ScenarioConfigs
>(
  `${DETECTION_MODELS}/RETRIEVE_SCENARIO_CONFIGS`,
  async (_: void, { dispatch }) => {
    try {
      const response = await retrieveScenarioConfigs();
      // update the old store as well.
      // TODO: remove this once we deprecate the legacy actions.
      dispatch(retrieveScenarioConfigsSuccess(response));
      return response;
    } catch (e) {
      dispatch(sendErrorToast('Unable to retrieve scenario configs.'));
      throw e;
    }
  },
);

export const validateDetectionModelThunk = u21CreateAsyncThunk<
  ValidateModelPayload & { history: History },
  void
>(
  `${DETECTION_MODELS}/VALIDATE_DETECTION_MODEL`,
  async ({ history, ...payload }, { dispatch }) => {
    try {
      const res = await validateDetectionModel(payload);
      history.push(
        ROUTES_MAP.detectionModelsValidationId.path.replace(
          ':id',
          `${res.rule_id}`,
        ),
      );
    } catch (e) {
      dispatch(sendErrorToast('Unable to validate detection model.'));
      throw e;
    }
  },
);

export const revalidateDetectionModelThunk = u21CreateAsyncThunk<
  ValidateModelPayload & { id: number; history: History },
  void
>(
  `${DETECTION_MODELS}/REVALIDATE_DETECTION_MODEL`,
  async ({ history, ...payload }, { dispatch }) => {
    try {
      const res = await revalidateDetectionModel(payload);
      history.push(
        ROUTES_MAP.detectionModelsValidationId.path.replace(
          ':id',
          `${res.rule_id}`,
        ),
      );
    } catch (e) {
      dispatch(sendErrorToast('Unable to revalidate detection model.'));
      throw e;
    }
  },
);

export const activateDetectionModelThunk = u21CreateAsyncThunk<
  ActivateDetectionModelPayload & { history: History },
  void
>(
  `${DETECTION_MODELS}/ACTIVATE_DETECTION_MODEL`,
  async ({ history, ...payload }, { dispatch }) => {
    try {
      const res = await activateDetectionModel(payload);
      history.push(
        ROUTES_MAP.detectionModelsIdTab.path.replace(':id', String(res.id)),
      );
    } catch (e) {
      dispatch(sendErrorToast('Unable to activate detection model.'));
      throw e;
    }
  },
);

export const activateSubSecondDetectionModelThunk = u21CreateAsyncThunk<
  ActivateSynchronousDetectionModelPayload & { history: History },
  void
>(
  `${DETECTION_MODELS}/ACTIVATE_SUB_SECOND_DETECTION_MODEL`,
  async ({ history, ...payload }, { dispatch }) => {
    const payloadCopy = cloneDeep(payload);
    const spec = toIrSchema(payloadCopy.contents.specification);
    if (
      spec.rule_condition &&
      spec.rule_condition.type === 'rule_condition_composite'
    ) {
      spec.rule_condition = transformConditionOperatorsForBE(
        spec.rule_condition,
      );
    }
    payloadCopy.contents.specification = spec;

    try {
      const res = await activateSynchronousDetectionModel(payloadCopy);
      history.push(
        ROUTES_MAP.detectionModelsIdTab.path.replace(':id', String(res.id)),
      );
    } catch (e) {
      dispatch(
        sendErrorToast('Unable to activate synchronous detection model.'),
      );
      throw e;
    }
  },
);

export const editDetectionModelThunk = u21CreateAsyncThunk<
  EditRulePayload,
  DetectionModel
>(
  `${DETECTION_MODELS}/EDIT_DETECTION_MODEL`,
  async (payload: EditRulePayload, { dispatch }) => {
    try {
      const response = await editRule(payload);
      dispatch(sendSuccessToast(`Model edited successfully.`));
      return response;
    } catch (e) {
      dispatch(sendErrorToast(`Unable to edit detection model #${payload.id}`));
      throw e;
    }
  },
);

export const retrieveDetectionModelValidationResultsThunk = u21CreateAsyncThunk<
  { id: number },
  Array<DetectionModelValidationResult>
>(
  `${DETECTION_MODELS}/RETRIEVE_DETECTION_MODEL_VALIDATION_RESULTS`,
  async (payload: { id: number }, { dispatch }) => {
    try {
      return await retrieveDetectionModelValidationResults(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to retrieve rule validation results.'));
      throw e;
    }
  },
);

export const retrieveDetectionModelThunk = u21CreateAsyncThunk<
  number,
  DetectionModel
>(
  `${DETECTION_MODELS}/RETRIEVE_DETECTION_MODEL`,
  async (payload: number, { dispatch }) => {
    try {
      return await retrieveDetectionModel(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to retrieve rule.'));
      throw e;
    }
  },
);

export const retrieveDetectionModelValidationThunk = u21CreateAsyncThunk<
  number,
  DetectionModelValidation
>(
  `${DETECTION_MODELS}/RETRIEVE_DETECTION_MODEL_VALIDATION`,
  async (payload: number, { dispatch }) => {
    try {
      return await retrieveDetectionModelValidation(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to retrieve rule validation.'));
      throw e;
    }
  },
);

export const deactivateDetectionModelThunk = u21CreateAsyncThunk<
  {
    ruleId: number;
  },
  DeactivateRuleResponse
>(
  `${DETECTION_MODELS}/DEACTIVATE_DETECTION_MODEL`,
  async ({ ruleId }, { dispatch }) => {
    try {
      const result = await deactivateDetectionModel(ruleId);
      dispatch(sendSuccessToast(`Deactivate rule #${ruleId} successfully.`));
      return result;
    } catch (e) {
      dispatch(sendErrorToast(`Unable to deactivate rule #${ruleId}`));
      throw e;
    }
  },
);

export const retrieveRuleValidationAlertsInstrumentsThunk = u21CreateAsyncThunk<
  { id: number } & PaginationPayload
>(
  `${DETECTION_MODELS}/RETRIEVE_RULE_VALIDATION_INSTRUMENTS`,
  async (
    { id, ...payload }: { id: number } & PaginationPayload,
    { dispatch },
  ) => {
    try {
      const response = await retrieveAlertInstruments(id.toString(), payload);
      dispatch(
        retrieveRulesValidationAlertInstrumentsSuccess({
          id,
          instruments: response.instruments,
        }),
      );
    } catch (e) {
      dispatch(
        sendErrorToast(
          `Unable to retrieve sample rule validation instruments.`,
        ),
      );
      throw e;
    }
  },
);

export const retrieveRuleValidationAlertsActionEventsThunk =
  u21CreateAsyncThunk<
    { id: string | number } & PaginationPayload,
    RuleValidationAlertActionEventsResponse
  >(
    `${DETECTION_MODELS}/RETRIEVE_RULE_VALIDATION_ACTION_EVENTS}`,
    async ({ id, ...payload }, { dispatch }) => {
      try {
        const response = await retrieveAlertActionEvents({
          ...payload,
          alert_id: Number(id),
        });

        dispatch(
          retrieveRuleValidationAlertActionEventsSuccess({
            id,
            ...response,
          }),
        );
        return response;
      } catch (e) {
        dispatch(
          sendErrorToast(
            `Unable to retrieve sample rule validation action events.`,
          ),
        );
        throw e;
      }
    },
  );

export const retrieveDetectionModelsThunk = u21CreateAsyncThunk<
  void,
  DetectionModelsResponse
>(`${DETECTION_MODELS}/RETRIEVE_DETECTION_MODELS`, async (_, { dispatch }) => {
  try {
    return await retrieveDetectionModels();
  } catch (e) {
    dispatch(sendErrorToast(`Unable to retrieve all detection models.`));
    throw e;
  }
});

export const setLiveRuleFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${DETECTION_MODELS}/SET_LIVE_RULE_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.LIVE_RULE_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setValidatingRuleFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${DETECTION_MODELS}/SET_VALIDATING_RULE_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.VALIDATING_RULE_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setShadowRuleFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${DETECTION_MODELS}/SET_SHADOW_RULE_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.SHADOW_RULE_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

const detectionModelsSlice = u21CreateSlice({
  name: DETECTION_MODELS,
  initialState,
  reducers: {
    resetDmSlice: (draft) => {
      Object.assign(draft, initialState);
    },
    resetDetectionModelTestResults: (draft) => {
      Object.assign(
        draft.detectionModelTestResults,
        initialState.detectionModelTestResults,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // retrieve detection models thunk (counts and stuffs)
      .addLoadingCase(
        retrieveDetectionModelsThunk,
        'loadingRetrieveDetectionModels',
        (draft, { payload }) => {
          draft.count = payload.count;
        },
      )
      // retrieve detection model thunk
      .addLoadingCase(
        retrieveDetectionModelThunk,
        'loadingRetrieveDetectionModel',
        (draft, { payload }) => {
          draft.retrievedDetectionModel = payload;
        },
      )
      .addLoadingCase(
        testDetectionModelsThunk,
        'loadingDetectionModelTestResults',
        (draft, { payload }) => {
          const result = payload[0];
          draft.detectionModelTestResults = result;
        },
      )
      // retrieve detection model validation results thunk
      .addLoadingCase(
        retrieveDetectionModelValidationResultsThunk,
        'loadingRetrieveDetectionModelValidationResults',
        (draft, { payload }) => {
          draft.retrievedDetectionModelValidationResults = payload;
        },
      )
      // retrieve detection model validation
      .addLoadingCase(
        retrieveDetectionModelValidationThunk,
        'loadingRetrieveDetectionModelValidation',
        (draft, { payload }) => {
          draft.detectionModelValidation = payload;
        },
      )
      // validate detection model
      .addLoadingCase(
        validateDetectionModelThunk,
        'loadingValidateDetectionModel',
        (draft) => Object.assign(draft, initialState),
      )
      // revalidate detection model
      .addLoadingCase(
        revalidateDetectionModelThunk,
        'loadingRevalidateDetectionModel',
        (draft) => Object.assign(draft, initialState),
      )
      // retrieve scenario configs
      .addLoadingCase(
        retrieveScenarioConfigsThunk,
        'loadingScenarioConfigs',
        (draft, { payload }) => {
          draft.scenarioConfigs = payload;
        },
      )
      // edit detection model
      .addLoadingCase(
        editDetectionModelThunk,
        'loadingEditDetectionModel',
        (draft, { payload }) => {
          draft.retrievedDetectionModel = payload;
        },
      )
      // deactivate detection model
      .addLoadingCase(
        deactivateDetectionModelThunk,
        'loadingDeactivateDetectionModel',
        (draft) => {
          draft.retrievedDetectionModel.status = 'INACTIVE';
        },
      )
      .addLoadingCase(
        retrieveRuleValidationAlertsInstrumentsThunk,
        'loadingRetrieveRuleValidationAlertsInstruments',
      )
      .addCase(setLiveRuleFilters.fulfilled, (draft, action) => {
        draft.liveRuleFilters = action.payload;
      })
      .addCase(setValidatingRuleFilters.fulfilled, (draft, action) => {
        draft.validatingRuleFilters = action.payload;
      })
      .addCase(setShadowRuleFilters.fulfilled, (draft, action) => {
        draft.shadowRuleFilters = action.payload;
      });
  },
});

export const DETECTION_MODELS_SLICE_NAME = detectionModelsSlice.name;
export const { resetDmSlice, resetDetectionModelTestResults } =
  detectionModelsSlice.actions;
export default detectionModelsSlice.reducer;
