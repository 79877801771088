import { useEffect, useMemo, useState } from 'react';
import {
  U21LoadError,
  U21Loading,
  U21NoData,
  U21Pagination,
  U21Section,
  U21Spacer,
  U21TableState,
  U21TitleCountLabel,
} from 'app/shared/u21-ui/components';
import { AdverseMediaMatchCard } from 'app/modules/watchlists/components/AdverseMediaMatchCard';
import { useGetAdverseMediaArticles } from 'app/modules/watchlists/queries/useGetAdverseMediaArticles';
import { AdverseMediaArticlesListRequest } from 'app/modules/watchlists/requests';
import { createPaginationPayload } from 'app/shared/utils/table';
import { selectAdverseMediaFilters } from 'app/modules/watchlists/selectors';
import { setAdverseMediaFilters } from 'app/modules/watchlists/sliceWatchlists';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { useLocalStorageState } from 'app/shared/hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { Filters } from 'app/modules/filters/components/Filters';
import { getValidFilters } from 'app/modules/filters/utils';
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import {
  createAdverseMediaFilters,
  getAdverseMediaFilters,
} from 'app/modules/watchlists/utils';
import { useGetAdverseMediaRiskCategories } from 'app/modules/watchlists/queries/useGetAdverseMediaRiskCategories';
import { FilterOption } from 'app/modules/filters/models';
import { ADVERSE_MEDIA_FILTER_KEY } from 'app/modules/watchlists/filters';

interface OwnProps {
  alertId: number;
}

const DEFAULT_PAGINATION_STATE = {
  page: 1,
  pageSize: 10,
  sortBy: [],
};

export const AdverseMediaMatchSection = ({ alertId }: OwnProps) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectAdverseMediaFilters);

  const [pinned, setPinned] = useLocalStorageState<string[]>(
    LocalStorageKeys.ADVERSE_MEDIA_PINNED_FILTERS,
    [ADVERSE_MEDIA_FILTER_KEY.CATEGORY],
  );

  const [paginationData, setPaginationData] = useState<U21TableState>(
    DEFAULT_PAGINATION_STATE,
  );

  const payload = useMemo<AdverseMediaArticlesListRequest>(
    () => ({
      ...createPaginationPayload(paginationData),
      ...createAdverseMediaFilters(filters),
    }),
    [filters, paginationData],
  );

  const {
    data: adverseMedia = { articles: [], count: 0 },
    isLoading: adverseMediaLoading,
    isError,
    isRefetching,
    refetch,
  } = useGetAdverseMediaArticles(alertId, payload);

  const { data: riskCategories = { risk_categories: [], count: 0 } } =
    useGetAdverseMediaRiskCategories(alertId);

  const filterOptions = useMemo<FilterOption[]>(
    () => getAdverseMediaFilters(riskCategories),
    [riskCategories],
  );

  useEffect(() => {
    const validFilters = getValidFilters(
      getLocalStorageJSON(LocalStorageKeys.ADVERSE_MEDIA_FILTERS),
      filterOptions,
    );

    if (validFilters.length !== filters.length) {
      dispatch(setAdverseMediaFilters(validFilters));
    }
  }, [dispatch, filterOptions, filters]);

  const renderAdverseMediaMatches = () => {
    if (adverseMediaLoading) {
      return <U21Loading loading />;
    }

    if (isError) {
      return (
        <U21LoadError
          label="adverse media matches"
          onTryAgain={() => refetch()}
        />
      );
    }

    if (!adverseMedia || adverseMedia.articles.length === 0) {
      return <U21NoData />;
    }

    return adverseMedia.articles.map((article) => (
      <AdverseMediaMatchCard key={article.title} article={article} />
    ));
  };

  return (
    <U21Section
      title={
        <U21TitleCountLabel count={adverseMedia.count} label="article">
          Adverse Media Matches
        </U21TitleCountLabel>
      }
    >
      <U21Spacer spacing={3}>
        <Filters
          filters={filters}
          onChange={(newFilters) => {
            dispatch(setAdverseMediaFilters(newFilters));
            setPaginationData((prevState) => ({
              ...prevState,
              page: 1,
            }));
          }}
          onPinChange={setPinned}
          options={filterOptions}
          pinned={pinned}
        />

        {renderAdverseMediaMatches()}
        <U21Pagination
          count={adverseMedia.count}
          onPageChange={(pageNum) => {
            setPaginationData((prevState) => ({
              ...prevState,
              page: pageNum,
            }));
          }}
          onPageSizeChange={(newPageSize) => {
            setPaginationData((prevState) => ({
              ...prevState,
              page: 1,
              pageSize: newPageSize,
            }));
          }}
          onRefresh={refetch}
          refreshLoading={isRefetching}
          page={paginationData.page}
          pageSize={paginationData.pageSize}
        />
      </U21Spacer>
    </U21Section>
  );
};
