import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Models
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { ActionEventSummary } from 'app/shared/events/models';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';

// Components
import { IconFlagX } from 'app/shared/components/Icons/IconFlagX';
import { IconFlag, IconLayoutColumns } from '@u21/tabler-icons';
import {
  U21Button,
  U21Section,
  U21Table,
  U21TableState,
} from 'app/shared/u21-ui/components';

// Selectors
import {
  selectActionEventsCount,
  selectActionEventsData,
  selectActionEventsLoading,
} from 'app/shared/events/selectors';
import { selectActionEventTableConfig } from 'app/shared/CustomConfig/selectors';
import { selectLinkAnalysisFilters } from 'app/modules/filtersOld/selectors';
import {
  selectAlertActionEvents,
  selectAlertEntities,
  selectAlertLoading,
  selectAlert,
  selectAlertActionEventsLoading,
} from 'app/modules/alerts/selectors';

// Actions
import { retrieveActionEvents } from 'app/shared/events/actions';
import {
  toggleTableConfigSidebar,
  toggleSidebar,
} from 'app/modules/sidebar/slice';
import { retrieveAlertActionEvents } from 'app/modules/alerts/actions';

// Utils
import {
  createPaginationPayload,
  createTableColumnConfig,
} from 'app/shared/utils/table';
import { ACTION_EVENTS_COLUMN_CONFIG } from 'app/modules/actionEvents/columns';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';

interface ActionTableFilters {
  entity_ids: (number | string)[];
  alert_id: number;
}

export const EventActivityTable = () => {
  const dispatch = useDispatch();

  const entities = useSelector(selectAlertEntities);
  const { showCurrentAlert, selectedEntity } = useSelector(
    selectLinkAnalysisFilters,
  );
  const currentEntity = useMemo(
    () => entities.find(({ id }) => id === selectedEntity),
    [selectedEntity, entities],
  );

  const entityActionEvents = useSelector(selectActionEventsData);
  const flaggedEvents = useSelector(selectAlertActionEvents);
  const entityEventsLoading = useSelector(selectActionEventsLoading);
  const flaggedEventsLoading = useSelector(selectAlertActionEventsLoading);
  const alertLoading = useSelector(selectAlertLoading);
  const tableConfig = useSelector(selectActionEventTableConfig);
  const entityEventsCount = useSelector(selectActionEventsCount);
  const alert = useSelector(selectAlert);
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);

  const loading = entityEventsLoading || alertLoading || flaggedEventsLoading;
  const count = showCurrentAlert ? flaggedEvents.count : entityEventsCount;
  const rows = showCurrentAlert ? flaggedEvents.events : entityActionEvents;

  const filters: ActionTableFilters = useMemo(
    () => ({
      entity_ids: currentEntity ? [currentEntity.id] : [],
      alert_id: alert.id,
    }),
    [currentEntity, alert.id],
  );

  const newColumns = useMemo(
    () =>
      createTableColumnConfig<ActionEventSummary>(
        tableConfig,
        ACTION_EVENTS_COLUMN_CONFIG,
      ),
    [tableConfig],
  );

  return (
    <U21Section
      titleIcon={showCurrentAlert ? <IconFlag /> : <IconFlagX />}
      action={
        <U21Button
          onClick={() => {
            dispatch(
              toggleTableConfigSidebar({
                tableConfigType: TableConfigType.ACTION_EVENT_TABLE,
              }),
            );
          }}
          startIcon={<IconLayoutColumns />}
        >
          Choose Columns
        </U21Button>
      }
      title={
        !showCurrentAlert && currentEntity?.name_readable
          ? `${currentEntity?.name_readable}'s Events`
          : `Flagged Events`
      }
    >
      <U21Table
        columns={newColumns}
        count={count}
        data={rows}
        defaultSortBy={[{ desc: true, id: 'id' }]}
        loading={Boolean(loading)}
        filters={filters}
        manualPagination
        onRowClick={
          hasReadEventsPermission
            ? (_, actionEvent: ActionEventSummary) => {
                dispatch(
                  toggleSidebar({
                    type: SidebarComponentTypes.ACTION_EVENT,
                    data: actionEvent,
                  }),
                );
              }
            : undefined
        }
        onStateChange={(
          state: U21TableState,
          { alert_id: alertID, ...tableFilters }: ActionTableFilters,
        ) => {
          if (showCurrentAlert) {
            if (alertID < 0) {
              return;
            }
            dispatch(
              retrieveAlertActionEvents({
                ...createPaginationPayload(state),
                alert_id: alertID,
              }),
            );
          } else {
            // only retrieve actions if entities are associated with the alert
            if (
              tableFilters?.entity_ids?.length === 0 ||
              selectedEntity === -1
            ) {
              return;
            }

            dispatch(
              retrieveActionEvents({
                ...createPaginationPayload(state),
                ...tableFilters,
              }),
            );
          }
        }}
      />
    </U21Section>
  );
};
