import { useSelector } from 'react-redux';

import { U21Spacer } from 'app/shared/u21-ui/components';
import { AdverseMediaMatchSection } from 'app/modules/watchlists/components/AdverseMediaMatchSection';
import { AdverseMediaRiskCategoriesSection } from 'app/modules/watchlists/components/AdverseMediaRiskCategoriesSection';

import { selectAlert } from 'app/modules/alerts/selectors';

export const AdverseMediaMatches = () => {
  const { id: alertId } = useSelector(selectAlert);

  return (
    <U21Spacer>
      <AdverseMediaRiskCategoriesSection alertId={alertId} />
      <AdverseMediaMatchSection alertId={alertId} />
    </U21Spacer>
  );
};
