import { useQuery } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';
import { AdverseMediaArticleListResponse } from 'app/modules/watchlists/responses';
import { WATCHLIST_QUERY_KEYS } from 'app/modules/watchlists/queries/keys';
import { AdverseMediaArticlesListRequest } from 'app/modules/watchlists/requests';

const getAdverseMediaArticles = (
  alertId: number,
  payload: AdverseMediaArticlesListRequest,
): Promise<AdverseMediaArticleListResponse> =>
  post<AdverseMediaArticleListResponse>(
    `/watchlists/alerts/${alertId.toString()}/adverse-media-articles/list`,
    payload,
  );

export const useGetAdverseMediaArticles = (
  alertId: number,
  payload: AdverseMediaArticlesListRequest,
) => {
  return useQuery({
    queryKey: WATCHLIST_QUERY_KEYS.getAlertAdverseMediaArticles(
      alertId,
      payload,
    ),
    queryFn: () => getAdverseMediaArticles(alertId, payload),
    meta: {
      errorMessage:
        'Unable to retrieve watchlist result data. Please try again',
    },
    enabled: alertId > 0,
  });
};
