// Models
import {
  CensorshipActionTypes,
  CensorshipState,
} from 'app/modules/censorship/models';

// Reducers
import { initialState } from 'app/modules/censorship/reducer';

// Selectors
import { createSelector } from 'reselect';
import { selectCensorshipTableConfig } from 'app/shared/CustomConfig/selectors';

export const selectUncensoredField = (state: RootState) =>
  state.censorship.uncensoredField;

export const selectCensorshipConditions = (
  state: RootState,
): CensorshipState['censorships'] => {
  return state.censorship.censorships || { ...initialState.censorships };
};

export const selectCensorshipVisibleTableColumns = createSelector(
  selectCensorshipTableConfig,
  (columns) => {
    return columns.filter((column) => !column.hidden);
  },
);

// Loading
export const selectCensorshipConditionsLoading = (state: RootState) =>
  Boolean(state.loading[CensorshipActionTypes.RETRIEVE_CENSORSHIP_CONDITIONS]);

export const selectDeleteOneCensorshipConditionLoading = (state: RootState) =>
  Boolean(state.loading[CensorshipActionTypes.DELETE_ONE_CENSORSHIP_CONDITION]);

export const selectUncensorFieldLoading = (state: RootState) =>
  Boolean(state.loading[CensorshipActionTypes.UNCENSOR_FIELD]);

export const selectCreateCensorshipLoading = (state: RootState) =>
  Boolean(state.loading[CensorshipActionTypes.CREATE_CENSORSHIP_CONDITION]);

export const selectUpdateCensorshipLoading = (state: RootState) =>
  Boolean(state.loading[CensorshipActionTypes.UPDATE_ONE_CENSORSHIP_CONDITION]);
