import uncensorField from 'app/modules/censorship/sagas/uncensorField';
import retrieveCensorshipConditions from 'app/modules/censorship/sagas/retrieveCensorships';
import createCensorshipCondition from 'app/modules/censorship/sagas/createCensorshipCondition';
import deleteOneCensorshipCondition from 'app/modules/censorship/sagas/deleteOneCensorshipCondition';
import updateCensorshipCondition from 'app/modules/censorship/sagas/updateOneCensorshipCondition';

const sagaRegistry = [
  uncensorField,
  retrieveCensorshipConditions,
  createCensorshipCondition,
  deleteOneCensorshipCondition,
  updateCensorshipCondition,
];

export default sagaRegistry;
