import { get, post, put } from 'app/shared/utils/fetchr';

// Actions
import {
  startImpersonation as startImpersonationAction,
  cancelImpersonation as cancelImpersonationAction,
} from 'app/modules/agentsOld/actions';

// Models
import { PaginatedAgentsPayload } from 'app/modules/agentsOld/models';

// Utils
import routes from 'app/shared/utils/routes';

export const retrieveDropdownAgents = (payload: PaginatedAgentsPayload) => {
  return post(routes.patronus.agentsAutocomplete, payload);
};

export const startImpersonation = (
  payload: Omit<
    ReturnType<typeof startImpersonationAction>['payload'],
    'authClient'
  >,
) => {
  return post(routes.patronus.agentsLogImpersonationStart, payload);
};

export const cancelImpersonation = (
  payload: Omit<
    ReturnType<typeof cancelImpersonationAction>['payload'],
    'authClient'
  >,
) => {
  return put(routes.patronus.agentsLogImpersonationCancelled, payload);
};

export const retrieveUncensorFields = () =>
  get(routes.patronus.agentsListUncensorFields);
