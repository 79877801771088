import { UpdateWatchlistConfigPayload } from 'app/modules/watchlists/requests';
import { PaginationPayload } from 'app/shared/pagination/models';

const BASE = ['watchlist'];

export const WATCHLIST_QUERY_KEYS = {
  getWatchlistProviders: () => [...BASE, 'providers'] as const,
  fetchWatchlistConfig: (payload: string) =>
    [...BASE, 'fetchConfig', payload] as const,
  updateWatchlistConfig: (payload: UpdateWatchlistConfigPayload) =>
    [...BASE, 'updateConfig', payload] as const,
  getAlertWatchlistResults: (alertId: number, payload: PaginationPayload) =>
    [...BASE, 'alertWatchlistResults', alertId, payload] as const,
  getWatchlistConfigForDataSettings: (dataSettingId: number) =>
    [...BASE, 'watchlistConfigForDataSettings', dataSettingId] as const,
  getAlertAdverseMediaRiskCategories: (alertId: number) =>
    [...BASE, 'alertAdverseMediaRiskCategories', alertId] as const,
  getAlertAdverseMediaArticles: (alertId: number, payload: PaginationPayload) =>
    [...BASE, 'alertAdverseMediaArticles', alertId, payload] as const,
  getWatchlistResultNames: (alertId: number) =>
    [...BASE, 'watchlistResultsNames', alertId] as const,
};
