import { PaginationPayload } from 'app/shared/models';

export enum CensorshipActionTypes {
  UNCENSOR_FIELD = '@@teams/UNCENSOR_FIELD',
  UNCENSOR_FIELD_SUCCESS = '@@teams/UNCENSOR_FIELD_SUCCESS',
  CLEAR_UNCENSORED_FIELD = '@@teams/CLEAR_UNCENSORED_FIELD',
  RETRIEVE_CENSORSHIP_CONDITIONS = '@@censorshipConditions/RETRIEVE_CENSORSHIP_CONDITIONS',
  RETRIEVE_CENSORSHIP_CONDITIONS_SUCCESS = '@@censorshipConditions/RETRIEVE_CENSORSHIP_CONDITIONS_SUCCESS',
  CREATE_CENSORSHIP_CONDITION = '@@censorshipConditions/CREATE_CENSORSHIP_CONDITION',
  CREATE_CENSORSHIP_CONDITION_SUCCESS = '@@censorshipConditions/CREATE_CENSORSHIP_CONDITION_SUCCESS',
  DELETE_ONE_CENSORSHIP_CONDITION = '@@censorshipConditions/DELETE_ONE_CENSORSHIP_CONDITION',
  DELETE_ONE_CENSORSHIP_CONDITION_SUCCESS = '@@censorshipConditions/DELETE_ONE_CENSORSHIP_CONDITION_SUCCESS',
  UPDATE_ONE_CENSORSHIP_CONDITION = '@@censorshipConditions/UPDATE_ONE_CENSORSHIP_CONDITION',
  UPDATE_ONE_CENSORSHIP_CONDITION_SUCCESS = '@@censorshipConditions/UPDATE_ONE_CENSORSHIP_CONDITION_SUCCESS',
}

export enum TargetTypes {
  TEAM = 'TEAM',
  AGENT = 'AGENT',
}

export interface CensorshipState {
  uncensoredField: UncensoredFieldResponse;
  censorships: GetCensorshipConditionsListResponse;
}

export type ObjectType = 'entity' | 'alert' | 'instrument' | 'transaction';

export interface UncensorFieldPayload {
  field: string;
  object_type: ObjectType;
  object_id: number;
}

export interface UncensoredFieldResponse {
  field: string;
  value: string;
}

// New PII Censorship Self-serve   ////////////////////////

interface CensorshipTeam {
  id: number;
  name: string;
  description: string;
}

interface CensorshipAgent {
  id: number;
  name: string;
}

export interface CensorshipField {
  id: number;
  name: string;
  display_name: string;
}

export interface CensorshipDetails {
  id: number;
  name: string;
  org_id: number;
  unit_id: number;
  fields: CensorshipField[];
  uncensor: CensorshipField[];
  target_type: TargetTypes;
  teams: CensorshipTeam[];
  agents: CensorshipAgent[];
  created_at: string | null;
  created_by_full_name: string;
  updated_at: string | null;
  updated_by_full_name: string;
  deleted_at: string | null;
  deleted_by_full_name: string;
}

export type GetCensorshipConditionsPayload = PaginationPayload;

export interface CreateCensorshipPayload {
  name: string;
  target_type: TargetTypes;
  team_ids: number[];
  agent_ids: number[];
  censored_fields_by_name: string[];
  uncensor_fields_by_name: string[];
}

export interface UpdateCensorshipPayload extends CreateCensorshipPayload {
  censorship_condition_id: number;
}

export interface GetCensorshipConditionsListResponse {
  censorships: CensorshipDetails[];
  count: number;
}
