import retrieveCensoredFields from 'app/modules/agentsOld/sagas/retrieveCensoredFields';
import startImpersonation from 'app/modules/agentsOld/sagas/startImpersonation';
import cancelImpersonation from 'app/modules/agentsOld/sagas/cancelImpersonation';

const sagaRegistry = [
  retrieveCensoredFields,
  startImpersonation,
  cancelImpersonation,
];

export default sagaRegistry;
