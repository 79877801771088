import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AgentsActionTypes } from 'app/modules/agentsOld/models';

// Actions
import { retrieveUncensorFieldsSuccess } from 'app/modules/agentsOld/actions';

// API
import { retrieveUncensorFields as retrieveUncensorFieldsApi } from 'app/shared/api/agents';

const rootAction = AgentsActionTypes.RETRIEVE_UNCENSOR_FIELDS;
function* retrieveUncensorFields() {
  const config = {
    rootAction,
    request: call(retrieveUncensorFieldsApi),
    success: function* onSuccess(result) {
      yield put(retrieveUncensorFieldsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveUncensorFields() {
  yield takeLatest(rootAction, retrieveUncensorFields);
}
