// Components
import { AuditTrail } from 'app/modules/alerts/components/AlertDetails/AuditTrail';
import { Notes } from 'app/modules/investigations/components/InvestigationTabs/Notes';
import { Documents } from 'app/modules/alerts/components/AlertDetails/Documents';
import { NarrativesList } from 'app/modules/narratives/components/NarrativesList';
import { CustomData } from 'app/modules/alerts/components/AlertDetails/CustomData';
import { DocumentsLabel } from 'app/modules/alerts/components/AlertDetails/Tabs/DocumentsLabel';
import { NotesLabel } from 'app/modules/alerts/components/AlertDetails/Tabs/NotesLabel';
import { ActionEventActivity } from 'app/modules/alerts/components/AlertDetails/ActionEventActivity/ActionEventActivity';
import {
  DEFAULT_CHAINALYSIS_TABS,
  DEFAULT_DARK_WEB_TABS,
  DEFAULT_TM_TABS,
  DEFAULT_WATCHLIST_TABS,
} from 'app/modules/alerts/components/AlertDetails/Tabs/DefaultAlertTabs';

// Types
import {
  AdditionalFilters,
  AlertDetailsTab,
  AlertType,
} from 'app/modules/alerts/types';

// Models
import { InvestigationType } from 'app/modules/investigations/models';
import { AlertDetails } from 'app/modules/alerts/models';

// Constants
import { INTUIT_ALERT_TABS } from 'app/modules/alerts/components/AlertDetails/Tabs/intuitAlertTabs';
import { RuleGraphTab } from 'app/modules/alerts/components/AlertDetails/Tabs/RuleGraphTab';
import {
  ALL_TYPES,
  ALL_TYPES_EXCEPT_CHAINALYSIS,
  ALL_TYPES_EXCEPT_DARK_WEB,
  TAB_PATHS,
} from 'app/modules/alerts/components/AlertDetails/Tabs/constants';
import { ConsortiumHitsTab } from 'app/modules/alerts/components/AlertDetails/Tabs/ConsortiumHitsTab';

export const getAllTabs = (alert: AlertDetails): AlertDetailsTab[] => {
  const { alert_type: alertType } = alert;
  const defaultTabs: AlertDetailsTab[] = [];
  if (alertType === AlertType.CHAINALYSIS) {
    defaultTabs.push(...DEFAULT_CHAINALYSIS_TABS);
  } else if (alertType === AlertType.WATCHLIST) {
    defaultTabs.push(...DEFAULT_WATCHLIST_TABS);
  } else if (alertType === AlertType.DARK_WEB) {
    defaultTabs.push(...DEFAULT_DARK_WEB_TABS);
  } else {
    defaultTabs.push(...DEFAULT_TM_TABS);
  }

  // Only show this tab if we have the correct content for the alert
  // (Alert generated by GBR rule)
  if (
    alert.alert_type !== AlertType.DARK_WEB &&
    alert.content?.result?.matches?.field
  ) {
    defaultTabs.push({
      label: 'Rule Graph',
      path: 'rule-graph',
      component: <RuleGraphTab />,
      hidden: true,
      visibleOn: {
        types: ALL_TYPES,
      },
    });
  }

  const inReview = alert.status === 'IN_REVIEW';
  return [
    ...defaultTabs,
    ...INTUIT_ALERT_TABS,

    {
      label: <DocumentsLabel />,
      labelString: 'Documents',
      path: TAB_PATHS.DOCUMENTS,
      component: <Documents />,
      hidden: true,
      visibleOn: {
        types: ALL_TYPES_EXCEPT_DARK_WEB,
        filters: [AdditionalFilters.INTUIT],
      },
    },
    {
      label: 'Narrative',
      path: TAB_PATHS.NARRATIVE,
      component: (
        <NarrativesList readOnly={inReview} type={InvestigationType.ALERT} />
      ),
      hidden: true,
      visibleOn: {
        types: ALL_TYPES_EXCEPT_CHAINALYSIS,
      },
    },
    {
      label: <NotesLabel />,
      labelString: 'Notes',
      path: TAB_PATHS.NOTES,
      component: <Notes disabled={inReview} type={InvestigationType.ALERT} />,
      hidden: true,
      visibleOn: {
        types: ALL_TYPES,
      },
    },
    {
      label: 'Audit Trail',
      path: TAB_PATHS.AUDIT_TRAIL,
      component: <AuditTrail />,
      hidden: true,
      visibleOn: {
        types: ALL_TYPES,
        filters: [AdditionalFilters.INTUIT],
      },
    },
    {
      label: 'Custom Data',
      path: TAB_PATHS.CUSTOM_DATA,
      component: <CustomData />,
      hidden: true,
      visibleOn: {
        types: ALL_TYPES,
        filters: [AdditionalFilters.INTUIT],
      },
    },
    {
      label: 'Action Activity',
      path: 'action-activity',
      component: <ActionEventActivity />,
      hidden: true,
      visibleOn: {
        types: [AlertType.TM, AlertType.MANUAL],
      },
    },
    {
      label: 'Consortium Hits',
      path: TAB_PATHS.CONSORTIUM_HITS,
      component: <ConsortiumHitsTab />,
      visibleOn: {
        types: ALL_TYPES,
      },
    },
  ];
};
