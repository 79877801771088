// Types
import { AlertType } from 'app/modules/alerts/types';

export const ALL_TYPES = [
  AlertType.TM,
  AlertType.CAR,
  AlertType.MANUAL,
  AlertType.KYC,
  AlertType.CHAINALYSIS,
  AlertType.WATCHLIST,
  AlertType.DARK_WEB,
];

export const ALL_TYPES_EXCEPT_CHAINALYSIS = ALL_TYPES.filter(
  (alertType) => alertType !== AlertType.CHAINALYSIS,
);

export const ALL_TYPES_EXCEPT_DARK_WEB = ALL_TYPES.filter(
  (alertType) => alertType !== AlertType.DARK_WEB,
);

export const TAB_PATHS = {
  ADVERSE_MEDIA_MATCHES: 'adverse-media-matches',
  ALERT_SCORE: 'alert-score',
  AUDIT_TRAIL: 'audit-trail',
  CHECKLIST: 'investigation-checklist',
  DOCUMENTS: 'documents',
  NARRATIVE: 'narrative',
  NOTES: 'notes',
  CONSORTIUM_HITS: 'consortium-hits',
  CUSTOM_DATA: 'custom-data',
};

export const SIDE_BY_SIDE_IGNORED_PATHS = new Set([
  TAB_PATHS.AUDIT_TRAIL,
  TAB_PATHS.CHECKLIST,
  TAB_PATHS.DOCUMENTS,
  TAB_PATHS.NARRATIVE,
  TAB_PATHS.NOTES,
]);
