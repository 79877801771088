import { SarConfig } from 'app/modules/fincenSARSettings/responses';
import { INITIAL_SAR_CONTENT } from 'app/modules/fincenSarNew/constants';
import {
  AlertConfig,
  DefaultTagConfig,
  LumosConfig,
  OrgScenarioConfig,
  QueryBuilderConfig,
  RuleExecutionConfig,
} from 'app/modules/orgSettings/models';
import { CurrencyConfig, OrgEnums } from 'app/modules/orgSettings/responses';
import { RealTimeRulesConfig } from 'app/modules/orgManagement/responses';

export const INITIAL_SAR_CONFIG: SarConfig = {
  ...INITIAL_SAR_CONTENT['1'].content,
  page2: { ...INITIAL_SAR_CONTENT['2'].content },
};

export const INITIAL_SCENARIO_CONFIG: OrgScenarioConfig = {
  enabled_scenarios: [],
  label_mappings: {},
  values: {
    events: {},
    entities: {},
    instruments: {},
    validation_windows_generation_limit: 1000,
  },
};

export const INITIAL_QUERY_BUILDER_CONFIG: QueryBuilderConfig = {
  fields: {},
  special_config: {
    event_types: [],
    internal_customer_data_fields: [],
    numeric_fields: [],
    field_comparison_enabled: false,
    show_top_filters: false,
  },
  advanced_config: {},
  type: 'standard',
  aggregate_fields: {},
};

export const INITIAL_RULE_EXECUTION_CONFIG: RuleExecutionConfig = {};
export const INITIAL_REAL_TIME_RULES_CONFIG: RealTimeRulesConfig = {
  enabled: false,
};

export const INITIAL_CURRENCY_CONFIG: CurrencyConfig = {
  USD: {
    symbol: '$',
    max_decimal_places: 2,
  },
  BTC: {
    symbol: '₿ ',
    max_decimal_places: 18,
  },
  ETH: {
    symbol: 'Ξ ',
    max_decimal_places: 18,
  },
  NGN: {
    symbol: '₦ ',
    max_decimal_places: 2,
  },
  EUR: {
    symbol: '€',
    max_decimal_places: 2,
  },
};

export const INITIAL_DEFAULT_TAG: DefaultTagConfig = {
  name: '',
  tag_types: [],
};

export const INITIAL_LUMOS_CONFIG: LumosConfig = {
  allowed_tags_enabled: false,
  entity_page: {
    external_links: [],
    user: [],
    business: [],
    tabs: [],
  },
  txn_instruments_page: [],
  default_tag_config: INITIAL_DEFAULT_TAG,
};

export const INITIAL_ORG_ENUMS: OrgEnums = {
  standard: {
    entity_statuses: {
      field: 'status',
      values: [],
      section: 'entity',
    },
  },
};

export const INITIAL_ALERT_CONFIG: AlertConfig = {};
