// Redux
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// Models
import {
  UncensoredFieldResponse,
  CensorshipState,
  CensorshipActionTypes,
  GetCensorshipConditionsListResponse,
  CensorshipDetails,
} from 'app/modules/censorship/models';

// Actions
import { CensorshipActions } from 'app/modules/censorship/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialUncensoredField: UncensoredFieldResponse = {
  field: '',
  value: '',
};

const initialCensorshipConditions: GetCensorshipConditionsListResponse = {
  censorships: [],
  count: 0,
};

const initialState: CensorshipState = {
  uncensoredField: { ...initialUncensoredField },
  censorships: { ...initialCensorshipConditions },
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<CensorshipState> = (
  state = initialState,
  action: CensorshipActions,
) => {
  return produce(state, (draft: Draft<CensorshipState>) => {
    switch (action.type) {
      case CensorshipActionTypes.UNCENSOR_FIELD_SUCCESS:
        draft.uncensoredField = action.payload;
        return;

      case CensorshipActionTypes.CLEAR_UNCENSORED_FIELD:
        draft.uncensoredField = { ...initialUncensoredField };
        return;

      case CensorshipActionTypes.RETRIEVE_CENSORSHIP_CONDITIONS_SUCCESS:
        draft.censorships = action.payload;
        return;

      case CensorshipActionTypes.CREATE_CENSORSHIP_CONDITION_SUCCESS: {
        const newCensorshipCondition = action.payload as CensorshipDetails;

        draft.censorships.censorships = [
          newCensorshipCondition,
          ...draft.censorships.censorships,
        ];
        return;
      }

      case CensorshipActionTypes.UPDATE_ONE_CENSORSHIP_CONDITION_SUCCESS: {
        draft.censorships.censorships = (
          draft.censorships.censorships || []
        ).map((censorship) => {
          if (censorship.id === action.payload.id) {
            return action.payload;
          }
          return censorship;
        });
        return;
      }

      case CensorshipActionTypes.DELETE_ONE_CENSORSHIP_CONDITION_SUCCESS:
        draft.censorships.censorships = draft.censorships.censorships.filter(
          (censorship) => censorship.id !== action.payload,
        );
        return;

      default:
        return;
    }
  });
};

export { reducer as censorshipReducer, initialState };
